import toast from "react-hot-toast";
import { addressEndpoints } from "../apis";
import { apiConnector } from "../apiConnector";

const {
  GET_ADDRESS_API,
  CREATE_ADDRESS_API,
  UPDATE_ADDRESS_API,
  DELETE_ADDRESS_API,
} = addressEndpoints;

export const getAllAddress = async (token) => {
  let result = [];
  try {
    const response = await apiConnector("GET", GET_ADDRESS_API, null, {
      Authorization: `Bearer ${token}`,
    });

    if (response?.data?.error) {
      throw new Error(response?.data?.message);
    }
    result = response?.data;
  } catch (error) {
    console.log("GET_ADDRESS_API API ERROR............", error);
    toast.error(error.message);
  }
  return result;
};

export const addAddress = async (token, data) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector("POST", CREATE_ADDRESS_API, data, {
      Authorization: `Bearer ${token}`,
    });
    if (response?.data?.error) {
      throw new Error(response.data.error);
    }
    result = response?.data?.address;
    toast.success("Address Added");
  } catch (error) {
    console.log("ADD_ADDRESS_API API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};

export const updateAddress = async (token, data) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector("PUT", UPDATE_ADDRESS_API, data, {
      Authorization: `Bearer ${token}`,
    });
    if (response?.data?.error) {
      throw new Error(response.data.error);
    }
    result = response?.data?.address;
    toast.success("Address Updated");
  } catch (error) {
    console.log("UPDATE_ADDRESS_API API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};

export const deleteAddress = async (token, data) => {
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector("DELETE", DELETE_ADDRESS_API, data, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    if (response?.data?.error) {
      throw new Error("Could Not Delete Address");
    }
    toast.success("Address Deleted");
  } catch (error) {
    console.log("DELETE_ADDRESS_API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
};
