import React from "react";
import { Skeleton } from "antd";
import { DotChartOutlined } from "@ant-design/icons";

const HomeSkeleton = () => {
  return (
    <div className="w-full flex justify-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)]">
      <div className="flex flex-col w-11/12 max-w-maxContent gap-y-8">
        <div className="w-full border-b border-black/10 pt-4 pb-6">
          <div className="mt-6 w-full">
            <div className="w-full">
              <div className="w-full flex flex-col gap-y-6">
                <div className="w-full md:w-11/12 flex items-center justify-start">
                  <h3 className="text-base sm:text-xl md:text-2xl font-bold text-black/75">
                    <Skeleton.Input active={true} size="large" block={true} />
                  </h3>
                </div>
                <div className=" hidden md:flex items-center w-full">
                  <div>
                    <Skeleton.Avatar active={true}>
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>
                  </div>
                  <div className="grid grid-cols-4 w-full">
                    <Skeleton.Avatar
                      active={true}
                      size={120}
                      className="mx-auto"
                    >
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>

                    <Skeleton.Avatar
                      active={true}
                      size={120}
                      className="mx-auto"
                    >
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>

                    <Skeleton.Avatar
                      active={true}
                      size={120}
                      className="mx-auto"
                    >
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>

                    <Skeleton.Avatar
                      active={true}
                      size={120}
                      className="mx-auto"
                    >
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>
                  </div>
                  <div className="">
                    <Skeleton.Avatar active={true}>
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>
                  </div>
                </div>
                <div className="hidden xs:flex md:hidden items-center w-full">
                  <div>
                    <Skeleton.Avatar active={true}>
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>
                  </div>
                  <div className="grid grid-cols-3 w-full">
                    <Skeleton.Avatar
                      active={true}
                      size={85}
                      className="mx-auto"
                    >
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>

                    <Skeleton.Avatar
                      active={true}
                      size={85}
                      className="mx-auto"
                    >
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>

                    <Skeleton.Avatar
                      active={true}
                      size={85}
                      className="mx-auto"
                    >
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>
                  </div>
                  <div className="">
                    <Skeleton.Avatar active={true}>
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>
                  </div>
                </div>
                <div className="flex xs:hidden items-center w-full">
                  <div>
                    <Skeleton.Avatar active={true}>
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>
                  </div>
                  <div className="grid grid-cols-2 w-full">
                    <Skeleton.Avatar
                      active={true}
                      size={75}
                      className="mx-auto"
                    >
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>

                    <Skeleton.Avatar
                      active={true}
                      size={75}
                      className="mx-auto"
                    >
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>
                  </div>
                  <div className="">
                    <Skeleton.Avatar active={true}>
                      <DotChartOutlined
                        style={{ fontSize: 40, color: "#bfbfbf" }}
                      />
                    </Skeleton.Avatar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-9">
          <div className="h-full w-full md:w-11/12 flex items-center justify-between">
            <h3 className="text-base sm:text-xl md:text-2xl font-bold text-black/75">
              <Skeleton.Input active={true} size="large" block={true} />
            </h3>
            <div></div>
          </div>
          <div>
            <div className="w-full flex flex-col items-center justify-center gap-y-8">
              <div className="w-full">
                <Skeleton.Input active={true} size="large" block={true} />
              </div>
              <div className="w-full">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-10 w-full">
                  <div className="flex justify-center items-center w-full">
                    <div className="hidden sm:flex flex-col gap-y-2 ">
                      <Skeleton.Avatar active={true} size={200} shape="squre" />
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-y-1 ">
                          <p className="text-sm text-wrap">
                            <Skeleton.Input
                              active={true}
                              size="small"
                              block={true}
                            />
                          </p>
                          <Skeleton.Input active={true} size="small" />
                        </div>
                      </div>
                    </div>
                    <div className="sm:hidden w-full h-full flex flex-col justify-between">
                      <div className="flex flex-row gap-x-3 w-full">
                        <div className="relative w-44">
                          <Skeleton.Avatar
                            active={true}
                            size={100}
                            shape="squre"
                          />
                        </div>
                        <div className="w-full pr-0.5 pb-1 xs:pr-0 xs:pb-0">
                          <div className="flex flex-col justify-around min-w-20 gap-1">
                            <div className="flex flex-col gap-1">
                              <p className="text-xs xs:text-sm sm:text-base">
                                <Skeleton.Input
                                  active={true}
                                  size="small"
                                  block={true}
                                />
                              </p>
                            </div>
                            <p className="flex items-center text-xs xs:text-sm">
                              <Skeleton.Input active={true} size="small" />
                            </p>
                          </div>
                          <p className="flex items-center text-xs xs:text-sm mt-2">
                            <Skeleton.Input active={true} size="small" />
                          </p>
                        </div>
                      </div>
                      <div className="h-[1px] bg-black/10 mt-4"></div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center w-full">
                    <div className="hidden sm:flex flex-col gap-y-2 ">
                      <Skeleton.Avatar active={true} size={200} shape="squre" />
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-y-1 ">
                          <p className="text-sm text-wrap">
                            <Skeleton.Input
                              active={true}
                              size="small"
                              block={true}
                            />
                          </p>
                          <Skeleton.Input active={true} size="small" />
                        </div>
                      </div>
                    </div>
                    <div className="sm:hidden w-full h-full flex flex-col justify-between">
                      <div className="flex flex-row gap-x-3 w-full">
                        <div className="relative w-44">
                          <Skeleton.Avatar
                            active={true}
                            size={100}
                            shape="squre"
                          />
                        </div>
                        <div className="w-full pr-0.5 pb-1 xs:pr-0 xs:pb-0">
                          <div className="flex flex-col justify-around min-w-20 gap-1">
                            <div className="flex flex-col gap-1">
                              <p className="text-xs xs:text-sm sm:text-base">
                                <Skeleton.Input
                                  active={true}
                                  size="small"
                                  block={true}
                                />
                              </p>
                            </div>
                            <p className="flex items-center text-xs xs:text-sm">
                              <Skeleton.Input active={true} size="small" />
                            </p>
                          </div>
                          <p className="flex items-center text-xs xs:text-sm mt-2">
                            <Skeleton.Input active={true} size="small" />
                          </p>
                        </div>
                      </div>
                      <div className="h-[1px] bg-black/10 mt-4"></div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center w-full">
                    <div className="hidden sm:flex flex-col gap-y-2 ">
                      <Skeleton.Avatar active={true} size={200} shape="squre" />
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-y-1 ">
                          <p className="text-sm text-wrap">
                            <Skeleton.Input
                              active={true}
                              size="small"
                              block={true}
                            />
                          </p>
                          <Skeleton.Input active={true} size="small" />
                        </div>
                      </div>
                    </div>
                    <div className="sm:hidden w-full h-full flex flex-col justify-between">
                      <div className="flex flex-row gap-x-3 w-full">
                        <div className="relative w-44">
                          <Skeleton.Avatar
                            active={true}
                            size={100}
                            shape="squre"
                          />
                        </div>
                        <div className="w-full pr-0.5 pb-1 xs:pr-0 xs:pb-0">
                          <div className="flex flex-col justify-around min-w-20 gap-1">
                            <div className="flex flex-col gap-1">
                              <p className="text-xs xs:text-sm sm:text-base">
                                <Skeleton.Input
                                  active={true}
                                  size="small"
                                  block={true}
                                />
                              </p>
                            </div>
                            <p className="flex items-center text-xs xs:text-sm">
                              <Skeleton.Input active={true} size="small" />
                            </p>
                          </div>
                          <p className="flex items-center text-xs xs:text-sm mt-2">
                            <Skeleton.Input active={true} size="small" />
                          </p>
                        </div>
                      </div>
                      <div className="h-[1px] bg-black/10 mt-4"></div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center w-full">
                    <div className="hidden sm:flex flex-col gap-y-2 ">
                      <Skeleton.Avatar active={true} size={200} shape="squre" />
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-y-1 ">
                          <p className="text-sm text-wrap">
                            <Skeleton.Input
                              active={true}
                              size="small"
                              block={true}
                            />
                          </p>
                          <Skeleton.Input active={true} size="small" />
                        </div>
                      </div>
                    </div>
                    <div className="sm:hidden w-full h-full flex flex-col justify-between">
                      <div className="flex flex-row gap-x-3 w-full">
                        <div className="relative w-44">
                          <Skeleton.Avatar
                            active={true}
                            size={100}
                            shape="squre"
                          />
                        </div>
                        <div className="w-full pr-0.5 pb-1 xs:pr-0 xs:pb-0">
                          <div className="flex flex-col justify-around min-w-20 gap-1">
                            <div className="flex flex-col gap-1">
                              <p className="text-xs xs:text-sm sm:text-base">
                                <Skeleton.Input
                                  active={true}
                                  size="small"
                                  block={true}
                                />
                              </p>
                            </div>
                            <p className="flex items-center text-xs xs:text-sm">
                              <Skeleton.Input active={true} size="small" />
                            </p>
                          </div>
                          <p className="flex items-center text-xs xs:text-sm mt-2">
                            <Skeleton.Input active={true} size="small" />
                          </p>
                        </div>
                      </div>
                      <div className="h-[1px] bg-black/10 mt-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSkeleton;
