import React from "react";
import { Drawer } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { BiSolidLike } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import moment from "moment";
import { Card, Empty } from "antd";

const OrderRequestModal = (_this) => {
  return (
    <>
      <Drawer
        title={
          <div className="flex items-center justify-between">
            <AiOutlineClose
              onClick={() => _this.setRequestOrderModal(false)}
              size={22}
              className="text-gray-medium cursor-pointer"
            />
          </div>
        }
        placement="right"
        onClose={() => _this.setRequestOrderModal(false)}
        open={_this.requestOrderModal}
        width={window.innerWidth > 600 ? 550 : window.innerWidth - 50}
        closable={false}
      >
        <div className="flex flex-col gap-y-2 min-h-[calc(100vh-180px)] w-[100%] items-center">
          {_this.requestOrderData.length === 0 ? (
            <div className="flex items-center justify-center min-h-[calc(100vh-180px)] h-full">
              <Empty />
            </div>
          ) : (
            <div className="w-full min-w-max text-gray-500 dark:text-gray-400 flex flex-col gap-y-4">
              {_this?.requestOrderData?.map((order) => {
                return (
                  <Card
                    className={`min-h-36 w-full bg-grayBackground/30`}
                    bordered={true}
                    bodyStyle={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                    }}
                    headStyle={{
                      padding: 0,
                      justifyContent: "flex-start",
                    }}
                    title={
                      <div className="flex items-center py-3 px-3">
                        <p className="text-xs font-normal text-primaryColor">
                          {order._id}
                        </p>
                        <div className="flex justify-end w-full h-full gap-x-2">
                          <button
                            onClick={() => {
                              _this?.orderAccept(order._id);
                            }}
                            className="px-2 py-1 rounded-sm bg-greenColor flex items-center justify-center gap-x-2 text-sm font-bold text-white"
                          >
                            <BiSolidLike className="text-md" />
                            Accept
                          </button>
                          <button
                            onClick={() => {
                              _this?.orderReject(order._id);
                            }}
                            className="px-2 py-1 rounded-sm bg-danger flex items-center justify-center gap-x-2 text-sm font-bold text-white"
                          >
                            <IoClose className="tex-md" />
                            Reject
                          </button>
                        </div>
                      </div>
                    }
                  >
                    <div className="flex flex-row w-full" style={{ flex: 1 }}>
                      <div className="flex-1 flex-col justify-center items-center w-full">
                        <div className="flex flex-col gap-1">
                          <div className="text-gray-medium text-sm font-normal leading-tight my-2">
                            <div className="flex flex-wrap gap-x-3 px-6">
                              {order.order_items
                                .slice(0, 3)
                                .map((items, index) => {
                                  return (
                                    <div
                                      key={items._id}
                                      className="flex flex-col items-center gap-y-1"
                                    >
                                      {/* <img
                                      alt="product_image"
                                      src={`${
                                        BASE_URL + items.product.product_image
                                      }`}
                                      className={`h-12 md:h-16 object-cover rounded-md ${
                                        order.order_status === "Cancelled" &&
                                        "grayscale"
                                      }`}
                                    /> */}
                                      <p className="text-xs text-light">
                                        {index + 1}
                                        {" . "}
                                        {items.product.product_name}
                                        {" × "}
                                        {items.quantity}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <div className="w-full h-[1px] bg-black/10"></div>
                          <div className="flex justify-around gap-3 px-4 py-4">
                            <div className="p-4 border border-black/5 rounded-lg">
                              <div className="flex flex-col justify-between text-wrap max-w-44">
                                <p className="text-xs">
                                  Phone Number :{" "}
                                  {order.order_address.phone_number}
                                </p>
                                <p className="text-xs">
                                  Door/Flat : {order.order_address.door_flat}
                                </p>
                                <p className="text-xs">
                                  Street : {order.order_address.street}
                                </p>
                                <p className="text-xs">
                                  City : {order.order_address.city}
                                </p>
                                <p className="text-xs">
                                  Landmark : {order.order_address.land_mark}
                                </p>
                                <p className="text-xs">
                                  State : {order.order_address.state}
                                </p>
                                <p className="text-xs">
                                  Pin : {order.order_address.postal_code}
                                </p>
                              </div>
                            </div>
                            <div className="p-4 border border-black/5 rounded-lg">
                              <div className="flex flex-col justify-between">
                                <p className="text-xs">
                                  {moment(order.order_date).format(
                                    "Do MMMM YYYY, h:mm a"
                                  )}
                                </p>
                                <p className="text-xs text-wrap">
                                  Total Amount : {order.total_amount}
                                </p>
                                <p className="text-xs text-wrap">
                                  Payment Mod : {order.payment_mod}
                                </p>
                                <p className="text-xs text-wrap">
                                  {order.payment_mod === "Online" && (
                                    <span>Payment Id : {order.payment_id}</span>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default OrderRequestModal;
