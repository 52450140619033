import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";

export default function Upload({ value, setValue }) {
  const inputRef = useRef(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setValue({
        preview: URL.createObjectURL(file),
        data: file,
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "image/*": [".jpeg", ".jpg", ".png"] },
    onDrop,
  });
  return (
    <div className="flex flex-col space-y-2">
      <label className="text-sm text-subtext">
        Category Image <sup className="text-pink-200">*</sup>
      </label>
      <div
        className={`${
          isDragActive ? "bg-primaryColor" : "bg-white"
        } flex min-h-[250px] cursor-pointer items-center justify-center rounded-md border border-secondColor`}
      >
        {value !== null ? (
          <div className="flex w-full flex-col p-6">
            <img
              src={value?.preview}
              alt="Preview"
              className="h-40 w-full rounded-md object-cover"
            />
            <button
              type="button"
              onClick={() => {
                setValue(null);
              }}
              className="mt-3 text-primaryColor underline"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div
            className="flex w-full flex-col items-center p-6"
            {...getRootProps()}
          >
            <input {...getInputProps()} ref={inputRef} />
            <div className="grid aspect-square w-14 place-items-center rounded-full bg-pure-greys-800">
              <FiUploadCloud className="text-2xl text-primaryColor" />
            </div>
            <p className="mt-2 max-w-[200px] text-center text-sm text-richblack-200">
              Drag and drop an image, or click to{" "}
              <span className="font-semibold text-primaryColor">Browse</span> a
              file
            </p>
            <ul className="mt-10 flex list-disc justify-between space-x-12 text-center  text-xs text-richblack-200">
              <li>Aspect ratio 16:9</li>
              <li>Recommended size 1024x576</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
