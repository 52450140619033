import React from "react";
import "./style.css";
import logo from "../../assets/logo/logo.png";
const PreLoader = () => {
  return (
    <div
      id="spinner"
      className="container min-h-[100vh] min-w-[100vw] bg-white z-[1000] fixed"
    >
      <img className="img" src={logo} alt="logo" />
    </div>
  );
};

export default PreLoader;
