import React from "react";
import { Card, ConfigProvider, Radio } from "antd";
import Individual from "./Individual";
import Partnership from "./Partnership";
import PrivateLtd from "./PrivateLtd";
import Loading from "./Loading";
import gif from "../../assets/Gif/LoadingAnimation.gif";
import SaltKey from "./SaltKey";

const Body = (_this) => {
  return (
    <>
      <Card
        title={
          <div className="mt-2 flex items-center justify-between py-2">
            <p className="text-sm sm:text-base lg:text-xl font-medium leading-loose">
              Online Payments
            </p>
          </div>
        }
        className="bg-bg-gray text-gray-medium tracking-wider flex flex-col justify-start w-full"
      >
        {_this?.loading === true ? (
          <div className="flex justify-center items-center min-h-[calc(100vh-180px)]">
            <div>
              <img src={gif} alt="" className="h-36 w-36" />
              <p>Sending Documents...</p>
            </div>
          </div>
        ) : (
          <div className="min-h-[calc(100vh-180px)]">
            {_this?.documentSend ? (
              <SaltKey {..._this} />
            ) : (
              <div className="flex flex-col gap-y-8">
                <div className="flex justify-center">
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#fc8019",
                      },
                    }}
                  >
                    <Radio.Group
                      defaultValue="1"
                      buttonStyle="solid"
                      onChange={(key) => {
                        _this?.setFormTab(key.target.value);
                      }}
                    >
                      <Radio.Button value="1">
                        Sole proprietor/individual
                      </Radio.Button>
                      <Radio.Button value="2">
                        FOR Partnership Firm
                      </Radio.Button>
                      <Radio.Button value="3">FOR Private Ltd</Radio.Button>
                    </Radio.Group>
                  </ConfigProvider>
                </div>
                {_this?.formTab === "1" && <Individual {..._this} />}
                {_this?.formTab === "2" && <Partnership {..._this} />}
                {_this?.formTab === "3" && <PrivateLtd {..._this} />}
              </div>
            )}
          </div>
        )}
      </Card>
    </>
  );
};

export default Body;
