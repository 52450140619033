import React from "react";
import { HiOutlineHome } from "react-icons/hi";
import { AiOutlineSetting } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { LuPackageOpen } from "react-icons/lu";
import { MdOutlinePayment } from "react-icons/md";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, ConfigProvider } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
const items = [
  {
    key: "1",
    label: (
      <div>
        <h5 className="text-sm">My Account</h5>
        <p className="text-xs font-light text-black/50">Address & Settings</p>
      </div>
    ),
    children: (
      <div className="flex flex-col gap-y-5 border-t border-dashed border-black/30 pt-4">
        <Link to="/customer/account/manage-address">
          <div className="text-black/60 flex justify-between">
            <div className="flex items-center gap-x-1 ">
              <HiOutlineHome className="text-lg" />
              <p>Manage Address</p>
            </div>
            <IoIosArrowForward />
          </div>
        </Link>
        {/* <div className=" text-black/60 flex justify-between">
          <div className="flex items-center gap-x-1 ">
            <AiOutlineSetting className="text-lg" />
            <p>Settings</p>
          </div>
          <IoIosArrowForward />
        </div> */}
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div>
        <h5 className="text-sm">Orders</h5>
        <p className="text-xs font-light text-black/50">Orders & Payments</p>
      </div>
    ),
    children: (
      <div className="flex flex-col gap-y-5 border-t border-dashed border-black/30 pt-4">
        <Link to="/customer/account/orders">
          <div className="text-black/60 flex justify-between">
            <div className="flex items-center gap-x-1 ">
              <LuPackageOpen className="text-lg" />
              <p>Orders</p>
            </div>
            <IoIosArrowForward />
          </div>
        </Link>
        {/* <div className=" text-black/60 flex justify-between">
          <div className="flex items-center gap-x-1 ">
            <MdOutlinePayment className="text-lg" />
            <p>Payments</p>
          </div>
          <IoIosArrowForward />
        </div> */}
      </div>
    ),
  },
];
const Body = (_this) => {
  const { user } = useSelector((state) => state.profile);
  return (
    <div className="w-full flex justify-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)]">
      <div className="flex flex-col w-11/12 sm:w-10/12 md:w-8/12 lg:w-6/12 max-w-maxContent gap-y-4 py-3">
        <div className="w-full">
          <div className="w-full flex justify-between">
            <div className="flex flex-col justify-center">
              <h4 className="text-base font-semibold uppercase text-black/80">
                {user.first_name + " " + user.last_name}
              </h4>
              <p className="text-xs text-subtext">{user.phone_number}</p>
            </div>
            <Link
              to="/customer/account/edit-profile"
              className="text-base font-bold text-primaryColor"
            >
              Edit
            </Link>
          </div>
          <div className="h-[2px] bg-black/50 mt-3"></div>
        </div>
        <div>
          <ConfigProvider
            theme={{
              components: {
                Collapse: {
                  headerBg: "#ffff",
                  headerPadding: "16px",
                },
              },
              token: {
                fontSize: 15,
              },
            }}
          >
            <Collapse
              accordion
              bordered={false}
              items={items}
              expandIconPosition="end"
              size="middle"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            />
          </ConfigProvider>
          <div className="h-[1px] bg-black/10 mt-0.5"></div>
        </div>
        <button
          onClick={() => _this.logOut()}
          className="w-full text-base font-bold text-white bg-primaryColor py-1.5 sm:py-2 mt-5"
        >
          Log out
        </button>
      </div>
    </div>
  );
};

export default Body;
