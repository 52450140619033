import React from "react";
import { Modal, ConfigProvider } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/operations/authApi";
import { MdLogout } from "react-icons/md";

const LogoutModal = (_this) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            borderRadiusLG: 0,
          },
        }}
      >
        <Modal
          open={_this.logoutModal}
          onCancel={() => _this.setLogoutModal(false)}
          centered={true}
          width={400}
          footer={
            <div className="flex justify-end gap-x-3">
              <button
                onClick={() => _this.setLogoutModal(false)}
                className="border-[2px] p-1.5 border-primaryColor text-primaryColor font-bold"
              >
                Cancel
              </button>
              <button
                onClick={() => dispatch(logout(navigate))}
                className="border-[2px] p-1.5 border-primaryColor bg-primaryColor text-white font-bold"
              >
                Logout
              </button>
            </div>
          }
        >
          <div className="flex justify-center items-center min-h-[80px] font-bold gap-x-2">
            <MdLogout className="text-danger text-3xl" /> Are you sure you want
            to Logout ?
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default LogoutModal;
