import toast from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { categoryEndpoints } from "../apis";

const {
  GET_ALL_CATEGORY_API,
  CREATE_CATEGORY_API,
  UPDATE_CATEGORY_API,
  GET_CATEGORY_API,
} = categoryEndpoints;

export const getAllCategory = async () => {
  let result = [];
  try {
    const response = await apiConnector("GET", GET_ALL_CATEGORY_API);
    if (!response?.data) {
      throw new Error("Could Not Fetch Product Categories");
    }
    result = response?.data;
  } catch (error) {
    console.log("PRODUCT_CATEGORY_API API ERROR............", error);
    toast.error(error.message);
  }
  return result;
};

export const createCategory = async (data, token) => {
  let result = null;
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector("POST", CREATE_CATEGORY_API, data, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
    if (response?.data?.error) {
      throw new Error("Could Not Add Category");
    }
    toast.success("Category Added");
    result = response?.data;
  } catch (error) {
    console.log("CREATE CATEGORY API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};

export const updateCategory = async (data, token) => {
  let result = null;
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector("PUT", UPDATE_CATEGORY_API, data, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });

    if (response?.data?.error) {
      throw new Error("Could Not Update Category");
    }
    toast.success("Category Updated");
    result = response?.data;
  } catch (error) {
    console.log("UPDATE CATEGORY API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};
