import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collapsed: null, // Null so that for the first time only we can check the device size and set accordingly
  activeRoute: "/dashboard/admin",
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    setCollapsed: (state, action) => {
      state.collapsed = action.payload;
    },
    setActiveRoute: (state, action) => {
      state.activeRoute = action.payload;
    },
  },
});

export const { setCollapsed, setActiveRoute } = navigationSlice.actions;
export default navigationSlice.reducer;
