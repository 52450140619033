import toast from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { authEndpoints } from "../apis";
import {
  setLoading,
  setToken,
  setStep,
  setSidebar,
} from "../../reducer/slices/authSlice";
import { setUser } from "../../reducer/slices/profileSlice";
import { ValidateEmail, ValidateMobile } from ".";

const {
  SEND_OTP_API,
  SIGNUP_API,
  LOGIN_API,
  UPDATE_PHONE_NUMBER,
  UPDATE_EMAIL,
  VERIFY_TOKEN_API,
} = authEndpoints;

export function sendOtp(data, setSignUpData = null) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SEND_OTP_API, data);
      if (response?.data?.error) {
        toast.dismiss(toastId);
        return toast.error(response?.data?.error);
      }
      toast.success("OTP Sent Successfully");
      dispatch(setStep(3));
      if (setSignUpData) {
        // Reset
        setSignUpData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          confirm_password: "",
        });
      }
    } catch (error) {
      console.log("SENDOTP API ERROR............", error);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function signUp(data) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SIGNUP_API, {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: data.password,
        confirm_password: data.confirm_password,
        account_type: data.account_type,
        otp: data.otp,
      });

      if (response?.data?.error) {
        toast.dismiss(toastId);
        toast.error(response?.data?.error);
      } else {
        dispatch(setToken(response.data.token));
        dispatch(setUser({ ...response.data.userData }));
        localStorage.setItem(
          "Caledon-Kabab-token",
          JSON.stringify(response.data.token)
        );
        localStorage.setItem(
          "Caledon-Kabab-user",
          JSON.stringify(response.data.userData)
        ); // important
        dispatch(setStep(1));
        toast.success("Signup Successful");
      }
    } catch (error) {
      console.log("SIGNUP API ERROR............", error);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function login(data, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const email = ValidateEmail(data.user) ? data.user : "";
      const phone_number = ValidateMobile(data.user) ? data.user : "";
      const password = data.password;
      const response = await apiConnector("POST", LOGIN_API, {
        email,
        phone_number,
        password,
      });

      if (response.data.error) {
        toast.dismiss(toastId);
        return toast.error(response?.data?.error);
      }
      dispatch(setToken(response.data.token));
      dispatch(setUser({ ...response.data.userData }));
      localStorage.setItem(
        "Caledon-Kabab-token",
        JSON.stringify(response.data.token)
      );
      localStorage.setItem(
        "Caledon-Kabab-user",
        JSON.stringify(response.data.userData)
      ); // important
      dispatch(setSidebar(false));
      if (response.data.userData.account_type === "Admin") {
        navigate("/dashboard/admin");
      }
      toast.success("Login Successful");
    } catch (error) {
      console.log("LOGIN API ERROR............", error);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    // dispatch(resetCart())
    localStorage.removeItem("Caledon-Kabab-token");
    localStorage.removeItem("Caledon-Kabab-user");
    toast.success("Logged Out");
    navigate("/");
  };
}

export function updatePhoneNumber(data, token, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    let result = false;
    dispatch(setLoading(true));
    try {
      if (!ValidateMobile(data.phone_number))
        throw new Error("Enter Valid Phone Number");
      const response = await apiConnector("PUT", UPDATE_PHONE_NUMBER, data, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.data.error) {
        toast.error(response.data.error);
        throw new Error(response.data.error);
      }

      if (data.otp === "") {
        toast.success("OTP sent");
      } else {
        console.log(response);
        dispatch(setUser({ ...response.data }));
        localStorage.setItem("user", JSON.stringify(response.data));
        toast.success("Phone Number Updated");
      }
      result = true;
    } catch (error) {
      console.log("UPDATE_PHONE_NUMBER API ERROR............", error);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
    return result;
  };
}

export function updateEmail(data, token, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    let result = false;
    dispatch(setLoading(true));
    try {
      console.log("hello");
      if (!ValidateEmail(data.email)) throw new Error("Enter Valid Email");
      const response = await apiConnector("PUT", UPDATE_EMAIL, data, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.data.error) {
        toast.error(response.data.error);
        throw new Error(response.data.error);
      }

      if (data.otp === "") {
        toast.success("OTP sent");
      } else {
        console.log(response);
        dispatch(setUser({ ...response.data }));
        localStorage.setItem("user", JSON.stringify(response.data));
        toast.success("Email Updated");
      }
      result = true;
    } catch (error) {
      console.log("UPDATE_EMAIL API ERROR............", error);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
    return result;
  };
}
