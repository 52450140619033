import React from "react";
import { Modal, ConfigProvider } from "antd";
import { TiWarningOutline } from "react-icons/ti";

const DeleteModal = (_this) => {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            borderRadiusLG: 0,
          },
        }}
      >
        <Modal
          open={_this.deleteModal}
          onCancel={() => _this.setDeleteModal(false)}
          centered={true}
          width={450}
          footer={
            <div className="flex justify-end gap-x-3">
              <button
                onClick={() => _this.setDeleteModal()}
                className="border-[2px] p-1.5 border-primaryColor text-primaryColor font-bold"
              >
                Cancel
              </button>
              <button
                onClick={() => _this.DeleteProduct(_this.deleteModal)}
                className="border-[2px] p-1.5 border-primaryColor bg-primaryColor text-white font-bold"
              >
                Delete
              </button>
            </div>
          }
        >
          <div className="flex justify-center items-center min-h-[80px] font-bold">
            <TiWarningOutline className="text-danger text-3xl" /> Are you sure?
            you want to delete this product
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default DeleteModal;
