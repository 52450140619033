import React from "react";
import { Card, ConfigProvider, Select, Badge } from "antd";
import { FaBell } from "react-icons/fa";
import OrdersTable from "./OrdersTable";

const Orders = (_this) => {
  const onChange = (key) => {
    _this.tabChangeHandler(key);
  };
  return (
    <div className="flex flex-col gap-y-2 min-h-[calc(100vh-190px)] w-[100%] justify-center overflow-x-hidden">
      <Card
        title={
          <div className="mt-2 flex items-center justify-between py-2">
            <p className="text-sm sm:text-base lg:text-xl font-medium leading-loose">
              Customers Order
            </p>
            <div className="flex items-center gap-x-3 pr-8">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#fc8019",
                  },
                }}
              >
                <Select
                  style={{
                    width: 200,
                  }}
                  placeholder="Select"
                  onChange={onChange}
                  optionFilterProp="children"
                  defaultValue={"1"}
                  options={[
                    {
                      value: "1",
                      label: "Active Orders",
                    },
                    {
                      value: "2",
                      label: "Complete Orders",
                    },
                    {
                      value: "3",
                      label: "Cancelled Orders",
                    },
                  ]}
                />
              </ConfigProvider>
              <button onClick={() => _this.setRequestOrderModal(true)}>
                <Badge count={_this?.requestOrderData.length}>
                  <FaBell className="text-xl text-black" />
                </Badge>
              </button>
            </div>
          </div>
        }
        className="bg-bg-gray text-gray-medium tracking-wider flex flex-col justify-start w-full"
      >
        <div className="flex min-h-[calc(100vh-180px)] w-[100%] justify-center">
          <OrdersTable {..._this} />
        </div>
      </Card>
    </div>
  );
};

export default Orders;
