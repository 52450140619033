import React from "react";
import { Modal } from "antd";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ProductModal = (_this) => {
  const product = _this?.productDetailsModal;
  console.log(product);
  return (
    <>
      <Modal
        title="Product Details"
        open={_this.productDetailsModal}
        onCancel={() => _this.setProductDetailsModal(false)}
        centered={true}
        width={500}
        footer={
          <div className="flex justify-between">
            <p className="flex items-center font-semibold">
              <FaIndianRupeeSign />
              {product.product_price}
            </p>
            {product.universal_availability && product?.is_available ? (
              <button
                className="border-2 border-primaryColor text-primaryColor font-bold py-1 px-2 flex items-center gap-2"
                onClick={() => _this.addToCartHandler(product)}
              >
                <FaPlus />
                Add
              </button>
            ) : (
              <p>Not Available</p>
            )}
            {product.universal_availabilityy === true}
          </div>
        }
      >
        <div className="flex justify-center my-">
          <div className="flex flex-col gap-y-2">
            <img
              alt="product_image"
              src={`${BASE_URL + product.product_image}`}
              className={`object-cover rounded-xl ${
                (!product.universal_availability || !product?.is_available) &&
                "grayscale"
              }`}
            />
            <div className="flex flex-col gap-y-1">
              <p className="text-lg text-wrap">{product.product_name}</p>
              <p className="text-sm text-wrap text-black/60">
                {product.product_description}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductModal;
