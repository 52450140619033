import React from "react";
import { Drawer } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import {
  MdOutlineHome,
  MdWorkOutline,
  MdOutlineLocationOn,
} from "react-icons/md";

const EditAddressSlider = (_this) => {
  return (
    <Drawer
      title={
        <div className="flex items-center justify-between gap-x-5">
          <div></div>
          <p>Edit Address</p>
          <AiOutlineClose
            onClick={() => _this.setEditAddressSlider(false)}
            size={22}
            className="text-gray-medium cursor-pointer"
          />
        </div>
      }
      placement="left"
      onClose={() => _this.setEditAddressSlider(false)}
      open={_this.editAddressSlider}
      width={window.innerWidth > 600 ? 600 : window.innerWidth}
      closable={false}
      key="left"
      footer={
        <div className="flex items-center justify-end gap-x-4 text-white font-bold py-3">
          <button
            onClick={() => _this.setEditAddressSlider(false)}
            className="border border-primaryColor text-primaryColor py-2 px-4"
          >
            Cancel
          </button>
          <button
            onClick={() => _this.editAddressSubmit()}
            className="bg-primaryColor py-2 px-4"
          >
            Save
          </button>
        </div>
      }
    >
      <div className="flex w-full justify-center">
        <div className="w-full flex flex-col items-center gap-y-4 sm:w-9/12">
          <form className="flex w-full flex-col gap-y-6">
            <label className="relative">
              <input
                required
                type="text"
                name="phone_number"
                maxLength="10"
                value={_this.editAddressData.phone_number}
                onChange={(e) => {
                  _this.setEditAddressData((prev) => ({
                    ...prev,
                    phone_number: e.target.value,
                  }));
                }}
                placeholder="Phone Number"
                className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
              />
              <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                Phone Number<sup className="text-pink-200">*</sup>
              </span>
            </label>
            <label className="relative">
              <input
                required
                type="text"
                name="door_flat"
                value={_this.editAddressData.door_flat}
                onChange={(e) => {
                  _this.setEditAddressData((prev) => ({
                    ...prev,
                    door_flat: e.target.value,
                  }));
                }}
                placeholder="Door / Flat No"
                className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
              />
              <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                Door / Flat No <sup className="text-pink-200">*</sup>
              </span>
            </label>
            <label className="relative">
              <input
                required
                type="text"
                name="street"
                value={_this.editAddressData.street}
                onChange={(e) => {
                  _this.setEditAddressData((prev) => ({
                    ...prev,
                    street: e.target.value,
                  }));
                }}
                placeholder="Street"
                className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
              />
              <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                Street <sup className="text-pink-200">*</sup>
              </span>
            </label>
            <label className="relative">
              <input
                required
                type="text"
                name="city"
                value={_this.editAddressData.city}
                onChange={(e) => {
                  _this.setEditAddressData((prev) => ({
                    ...prev,
                    city: e.target.value,
                  }));
                }}
                placeholder="Enter You City"
                className="w-full outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
              />
              <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                City <sup className="text-pink-200">*</sup>
              </span>
            </label>
            <label className="relative">
              <input
                required
                type="text"
                name="state"
                value={_this.editAddressData.state}
                onChange={(e) => {
                  _this.setEditAddressData((prev) => ({
                    ...prev,
                    state: e.target.value,
                  }));
                }}
                placeholder="Enter You State"
                className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
              />
              <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                State <sup className="text-pink-200">*</sup>
              </span>
            </label>
            <label className="relative">
              <input
                required
                type="text"
                name="land_mark"
                value={_this.editAddressData.land_mark}
                onChange={(e) => {
                  _this.setEditAddressData((prev) => ({
                    ...prev,
                    land_mark: e.target.value,
                  }));
                }}
                placeholder="Enter You Landmark"
                className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
              />
              <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                Landmark <sup className="text-pink-200">*</sup>
              </span>
            </label>
            <label className="relative">
              <input
                required
                type="text"
                name="postal_code"
                value={_this.editAddressData.postal_code}
                onChange={(e) => {
                  _this.setEditAddressData((prev) => ({
                    ...prev,
                    postal_code: e.target.value,
                  }));
                }}
                placeholder="Enter You Pin code"
                className="w-full outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
              />
              <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                Pin <sup className="text-pink-200">*</sup>
              </span>
            </label>
            <div className="w-full mx-auto flex flex-col gap-y-6">
              <div>
                <ul className="grid w-full grid-cols-3 border border-black/20 divide-x divide-black/20">
                  <li>
                    <input
                      type="radio"
                      id="home_radio"
                      name="address_type"
                      value="Home"
                      className="hidden peer"
                      checked={
                        _this.editAddressData.address_type === "Home"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        _this.setEditAddressData((prev) => ({
                          ...prev,
                          address_type: e.target.value,
                        }));
                      }}
                    />
                    <label
                      htmlFor="home_radio"
                      className="inline-flex items-center justify-center w-full p-3  text-black/40 bg-white cursor-pointer peer-checked:border-primaryColor peer-checked:text-primaryColor"
                    >
                      <div className="block">
                        <div className="flex w-full items-center text-sm md:text-lg md:font-semibold text-center">
                          <MdOutlineHome className="text-2xl mb-1" /> Home
                        </div>
                      </div>
                    </label>
                  </li>

                  <li>
                    <input
                      type="radio"
                      id="work_radio"
                      name="address_type"
                      value="Work"
                      className="hidden peer"
                      onChange={(e) => {
                        _this.setEditAddressData((prev) => ({
                          ...prev,
                          address_type: e.target.value,
                        }));
                      }}
                      checked={
                        _this.editAddressData.address_type === "Work"
                          ? true
                          : false
                      }
                    />
                    <label
                      htmlFor="work_radio"
                      className="inline-flex items-center justify-center w-full p-3 text-black/40 bg-white cursor-pointer peer-checked:border-primaryColor peer-checked:text-primaryColor"
                    >
                      <div className="block">
                        <div className="flex gap-x-1 w-full items-center text-sm md:text-lg md:font-semibold text-center ">
                          <MdWorkOutline className="text-xl" /> Work
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="other_radio"
                      name="address_type"
                      value="Other"
                      className="hidden peer"
                      onChange={(e) => {
                        _this.setEditAddressData((prev) => ({
                          ...prev,
                          address_type: e.target.value,
                        }));
                      }}
                      checked={
                        _this.editAddressData.address_type === "Other"
                          ? true
                          : false
                      }
                    />
                    <label
                      htmlFor="other_radio"
                      className="inline-flex items-center justify-center w-full p-3  text-black/40 bg-white cursor-pointer peer-checked:border-primaryColor peer-checked:text-primaryColor"
                    >
                      <div className="block">
                        <div className="flex w-full gap-x-1 items-center text-sm md:text-lg md:font-semibold text-center ">
                          <MdOutlineLocationOn className="text-xl mb-1" /> Other
                        </div>
                      </div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Drawer>
  );
};

export default EditAddressSlider;
