import { apiConnector, handleResponse } from "./core";
import { addressEndpoints } from "../apis";

const {
  GET_ADDRESS_API,
  CREATE_ADDRESS_API,
  UPDATE_ADDRESS_API,
  DELETE_ADDRESS_API,
} = addressEndpoints;

const address = {
  GetAllAddress: async (token) => {
    let response = null;
    try {
      response = await apiConnector("GET", GET_ADDRESS_API, null, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  CreateAddress: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("POST", CREATE_ADDRESS_API, data, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  UpdateAddress: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", UPDATE_ADDRESS_API, data, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  DeleteAddress: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("DELETE", DELETE_ADDRESS_API, data, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
};

export default address;
