import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import { useSelector, useDispatch } from "react-redux";
import Body from "./Body";
import Navbar from "../../components/Navbar";
import { addToCart } from "../../reducer/slices/cartSlice";
import Footer from "../../components/Footer";
import API from "../../services/api";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Home = () => {
  const dispatch = useDispatch();
  const { cart_items } = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [allProductData, setAllProductData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [productDetailsModal, setProductDetailsModal] = useState(false);

  // React.useEffect(() => {
  //   const getData = setTimeout(() => {
  //     getAllItems();
  //     getCategories();
  //   }, 2000);

  //   return () => clearTimeout(getData);
  // }, []);

  useEffect(() => {
    getAllItems();
    getCategories();
  }, []);

  const getCategories = async () => {
    setLoading(true);
    API.category
      .GetAllCategory()
      .then((response) => {
        setCategoryData(response);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  };

  const getAllItems = async () => {
    setLoading(true);
    API.product
      .GetAllProductsbyCategory()
      .then((response) => {
        if (response) {
          setProductData(response.allProductsbyCategory);
          setAllProductData(response.allProducts);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    const filteredSuggestions = allProductData.filter((item) =>
      item.product_name.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
    if (value === "") {
      setSuggestions([]);
    }
  };

  const addToCartHandler = (product) => {
    dispatch(addToCart(product));
  };

  // Socket
  useEffect(() => {
    const socket = io.connect(BASE_URL);
    const updateProductAvailability = (data) => {
      let updatedProduct = productData;
      for (const category of updatedProduct) {
        if (category.category_id === data.product_category) {
          for (const product of category.products) {
            if (product._id === data._id) {
              product.is_available = data.is_available;
              break;
            }
          }
          break;
        }
      }
      setProductData(updatedProduct);
    };
    socket.on(
      "receiving_update_product_availability",
      updateProductAvailability
    );
  }, [productData, setProductData]);

  const _this = {
    loading,
    setLoading,
    categoryData,
    setCategoryData,
    productData,
    setProductData,
    addToCartHandler,
    loading,
    setLoading,
    inputValue,
    setInputValue,
    suggestions,
    setSuggestions,
    handleInputChange,
    productDetailsModal,
    setProductDetailsModal,
  };
  return (
    <>
      <Navbar />
      <div
        className={`mt-[65px]  ${
          cart_items.length > 0 ? "mb-[124px]" : "mb-[65px]"
        } md:mb-[65px]`}
      >
        <Body {..._this} />
      </div>
      <Footer />
    </>
  );
};
export default Home;
