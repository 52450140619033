import React from "react";
import { Card, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AddCategoryModal from "./AddCategoryModal";
import { CategoryTable } from "./CategoryTable";
import EditCategoryModal from "./EditCategoryModal";

const Body = (_this) => {
  return (
    <>
      <Card
        title={
          <div className="mt-2 flex items-center justify-between py-2">
            <p className="text-sm sm:text-base lg:text-xl font-medium leading-loose">
              Categories
            </p>
            <button
              onClick={() => _this.addCategoryModalToggle()}
              className="text-white text-sm sm:text-base py-1.5 px-2.5 sm:py-2 sm:px-4 bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200  shadow-xl shadow-shadowColor border-0 focus:outline-none hover:bg-primary-dark rounded-lg font-medium inline-flex items-center"
            >
              <PlusOutlined className="mr-2" />
              Add Category
            </button>
          </div>
        }
        className="bg-bg-gray text-gray-medium tracking-wider flex flex-col justify-start w-full"
      >
        {_this.categoryData.length === 0 ? (
          <div className="flex flex-col items-center justify-center gap-y-2  min-h-[calc(100vh-190px)]">
            <Empty description={<span>No Category added yet</span>} />
          </div>
        ) : (
          <div className="flex min-h-[calc(100vh-190px)] max-h-[calc(100vh-190px)] w-[100%] justify-center">
            <CategoryTable {..._this} />
          </div>
        )}
      </Card>
      <AddCategoryModal {..._this} />
      <EditCategoryModal {..._this} />
    </>
  );
};

export default Body;
