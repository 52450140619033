import address from "./address";
import auth from "./auth";
import category from "./category";
import order from "./order";
import payment from "./payment";
import product from "./products";

const API = {
  auth,
  order,
  payment,
  product,
  category,
  address,
};

export default API;
