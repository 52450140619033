import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RiShoppingCartFill } from "react-icons/ri";

const CartPopUp = () => {
  const { total_items } = useSelector((state) => state.cart);
  return (
    <Link to="/cart">
      <div className="w-full bg-greenColor text-white px-3 py-3">
        <div className="flex flex-row items-center justify-between">
          <div className="font-semibold text-sm">
            {total_items} items in you cart
          </div>
          <div className="">
            <div className="flex items-center gap-x-2 font-semibold">
              Check out <RiShoppingCartFill className="text-xl" />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CartPopUp;
