import React from "react";
import { Steps, ConfigProvider } from "antd";
import { formattedDate } from "../../utils/dateFormatter";
import { LiaRupeeSignSolid } from "react-icons/lia";
import moment from "moment";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Body = (_this) => {
  const statusArray = [
    "Ordered",
    "Preparing",
    "Ready",
    "Delivery",
    "Reached",
    "Completed",
    "Cancelled",
  ];
  return (
    <div className="w-full flex justify-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)] bg-black/5">
      <div className="flex flex-col w-full h-full sm:w-10/12 md:w-8/12 lg:w-6/12 max-w-maxContent gap-y-4 py-3 ">
        <div className="w-full h-full flex flex-col gap-y-3">
          <span className="text-xs text-black/70 pl-3 uppercase">
            Orders Summary
          </span>
          <div className="h-[1px] bg-black/10 my-1"></div>
          {_this.loading ? (
            <div className="flex flex-col gap-y-3">Loading..</div>
          ) : (
            <div className="flex flex-col gap-y-4 h-full">
              <div className="flex flex-col gap-y-4 w-full mx-auto bg-white p-2 md:p-4 pt-5">
                {_this.orderData?.order_items?.map((items) => {
                  return (
                    <div
                      className=" w-full flex flex-col justify-between "
                      key={items?.product?._id}
                    >
                      <div className="flex flex-row gap-x-3 w-full">
                        <img
                          alt="product_image"
                          src={`${BASE_URL + items?.product?.product_image}`}
                          className="h-14 xs:h-16 max-h-20 object-cover rounded-md"
                        />
                        <div className="w-full flex flex-col justify-around pr-0.5 pb-1 xs:pr-0 xs:pb-0 xs:flex-row xs:justify-between">
                          <div className="flex w-full flex-col justify-around min-w-20 gap-1">
                            <p className="text-xs sm:text-sm">
                              {items?.product?.product_name}
                            </p>
                            <p className="flex items-center text-xs sm:text-sm font-light">
                              <LiaRupeeSignSolid />
                              {items?.product?.product_price}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="h-[1px] bg-black/5 mt-4"></div>
                    </div>
                  );
                })}
              </div>
              <div className="bg-white py-3 px-4">
                <div className="p-4 text-xs sm:text-base">
                  <div>
                    <h4 className="font-semibold text-black/70 pb-2">
                      Bill Details
                    </h4>
                    <div className="h-[1px] bg-black/5"></div>
                  </div>
                  <div className="pt-1 font-light text-black/70">
                    <p className="flex justify-between">
                      Total Items: <span>{_this.orderData?.total_items}</span>
                    </p>
                    <p className="flex justify-between">
                      Item Total:{" "}
                      <span className="flex items-center gap-x-0.5">
                        <LiaRupeeSignSolid />
                        {_this.orderData?.total_amount}
                      </span>
                    </p>
                    <p className="flex justify-between pb-3">
                      Delivey Fee:{" "}
                      <span className="flex items-center gap-x-0.5">
                        <LiaRupeeSignSolid />
                        {50}
                      </span>
                    </p>
                    <div className="h-[1px] bg-black/10"></div>
                  </div>
                  <div className="pt-3">
                    <p className="flex justify-between font-semibold  md:text-base text-black/70">
                      Total Payment :{" "}
                      <span className="flex items-center gap-x-0.5">
                        <LiaRupeeSignSolid />
                        {_this.orderData?.total_amount}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-white py-3 px-4">
                <div className="p-4 text-xs sm:text-base">
                  <div>
                    <h4 className="font-semibold text-black/70 pb-2">
                      Order Details
                    </h4>
                    <div className="h-[1px] bg-black/5"></div>
                  </div>
                  <div className="flex flex-col gap-y-2 pt-1 font-light text-black/70 ">
                    <p className="">
                      Order Id:{" "}
                      <span className="font-normal">
                        {_this.orderData?._id}
                      </span>
                    </p>
                    <p className="">
                      Payment:{" "}
                      <span className="flex items-center gap-x-0.5 font-normal">
                        {_this.orderData?.payment_mod === "Cod"
                          ? "Cash on Delivery"
                          : "Online"}
                      </span>
                    </p>
                    <p className="">
                      Delivey to:{" "}
                      <span className="flex items-center gap-x-0.5 text-black/90 font-normal">
                        {_this.orderData?.order_address?.street}
                      </span>
                    </p>
                    <p className="">
                      Order Placed:{" "}
                      <span className="flex items-center gap-x-0.5 text-black/90 font-normal">
                        {moment(_this.orderData?.order_date).format(
                          "MMMM Do YYYY, h:mm a"
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-white py-3 px-4">
                <div className="p-4 text-xs sm:text-base">
                  <div>
                    <div className="flex items-center justify-between pb-2">
                      <h4 className="font-semibold text-black/70 ">
                        Order Truck
                      </h4>
                      {_this.orderStatus !== "Cancelled" && (
                        <button
                          className="px-2 py-1 bg-primaryColor text-xs md:text-sm font-bold text-white"
                          onClick={() => {
                            _this.orderCancelRequest(_this.orderData);
                          }}
                        >
                          Cancel Order
                        </button>
                      )}
                    </div>
                    <div className="h-[1px] bg-black/5"></div>
                  </div>
                  <div className="flex flex-col gap-y-2 pt-1 font-light text-black/70 ">
                    {_this.orderStatus === "Cancelled" && (
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#fc8019",
                            // fontSize: "14",
                          },
                        }}
                      >
                        <Steps
                          progressDot
                          direction="vertical"
                          items={[
                            {
                              title: "Ordered",
                              description: "You have just Ordered.",
                            },
                            {
                              title: "Cancelled",
                              description: "Your order is Cancelled.",
                            },
                          ]}
                        />
                      </ConfigProvider>
                    )}
                    {_this.orderStatus !== "Cancelled" && (
                      <div>
                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: "#fc8019",
                              // fontSize: "14",
                            },
                          }}
                        >
                          <Steps
                            progressDot
                            current={statusArray.indexOf(_this.orderStatus)}
                            direction="vertical"
                            items={[
                              {
                                title: "Ordered",
                                description: "You have just Ordered.",
                              },
                              {
                                title: "Preparing",
                                description: "Your order is preparing.",
                              },
                              {
                                title: "Ready",
                                description: "Your order is ready.",
                              },
                              {
                                title: "Delivery",
                                description: "Out for Delivery.",
                              },
                              {
                                title: "Reached",
                                description:
                                  "Your order is reached to you doorstep.",
                              },
                              {
                                title: "Completed",
                                description: "Order has been completed.",
                              },
                            ]}
                          />
                        </ConfigProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Body;
