import React, { useState } from "react";
import toast from "react-hot-toast";
import Body from "./Body";
import { Container } from "../../components/DashbordSideBar";
import { useDispatch, useSelector } from "react-redux";
import API from "../../services/api";
import { setUser } from "../../reducer/slices/profileSlice";

const OnlinePayment = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const [loading, setLoading] = useState(false);
  const [documentSend, setDocumentSend] = useState(user?.document_send);
  const [saltKeyData, setSaltkeyData] = useState({
    m_id: "",
    salt_key: "",
  });
  const [formTab, setFormTab] = useState("1");
  const [individualFormData, setIndividualFormData] = useState({
    phone_number: "",
    email: "",
    gst: "",
    current_account: "",
    pan_card: "",
    fssai_licence: "",
  });

  const [partnershipForm, setPartnershipForm] = useState({
    phone_number: "",
    email: "",
    gst: "",
    current_account: "",
    partner_pan_card: "",
    partnership_deed: "",
    company_pan_card: "",
    fssai_licence: "",
  });

  const [pvtlmtdFormData, setPvtLmtdFormData] = useState({
    phone_number: "",
    email: "",
    gst: "",
    current_account: "",
    director_pan_card: "",
    certificate_corporation: "",
    company_pan_card: "",
    fssai_licence: "",
  });

  const maxSizeCheck = (files) => {
    console.log(files);
    if (files.size > 1048576) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const domain = document.location;

    const formValue = new FormData();
    if (formTab === "1") {
      if (
        individualFormData.email === "" ||
        individualFormData.phone_number === "" ||
        individualFormData.gst === "" ||
        individualFormData.current_account === "" ||
        individualFormData.pan_card === "" ||
        individualFormData.fssai_licence === ""
      ) {
        return toast.error("All Field required");
      }
      if (
        !maxSizeCheck(individualFormData.gst) ||
        !maxSizeCheck(individualFormData.current_account) ||
        !maxSizeCheck(individualFormData.pan_card) ||
        !maxSizeCheck(individualFormData.fssai_licence)
      ) {
        return toast.error("File should be less then 1mb");
      }

      formValue.append("type", "Individual");
      formValue.append("email", individualFormData?.email);
      formValue.append("phone_number", individualFormData?.phone_number);
      formValue.append("gst_file", individualFormData?.gst);
      formValue.append(
        "current_account_file",
        individualFormData?.current_account
      );
      formValue.append("pan_card_file", individualFormData?.pan_card);
      formValue.append("ffsai_licence_file", individualFormData?.fssai_licence);
      formValue.append("Domain", domain.host);
    } else if (formTab === "2") {
      if (
        partnershipForm.email === "" ||
        partnershipForm.phone_number === "" ||
        partnershipForm.gst === "" ||
        partnershipForm.current_account === "" ||
        partnershipForm.partner_pan_card === "" ||
        partnershipForm.partnership_deed === "" ||
        partnershipForm.company_pan_card === "" ||
        partnershipForm.fssai_licence === ""
      ) {
        return toast.error("All Field required");
      }

      if (
        !maxSizeCheck(partnershipForm.gst) ||
        !maxSizeCheck(partnershipForm.current_account) ||
        !maxSizeCheck(partnershipForm.partner_pan_card) ||
        !maxSizeCheck(partnershipForm.partnership_deed) ||
        !maxSizeCheck(partnershipForm.company_pan_card) ||
        !maxSizeCheck(partnershipForm.fssai_licence)
      ) {
        return toast.error("File should be less then 1mb");
      }

      formValue.append("type", "Partnership");
      formValue.append("email", partnershipForm?.email);
      formValue.append("phone_number", partnershipForm?.phone_number);
      formValue.append("gst_file", partnershipForm?.gst);
      formValue.append(
        "current_account_file",
        partnershipForm?.current_account
      );
      formValue.append(
        "partner_pan_card_file",
        partnershipForm?.partner_pan_card
      );
      formValue.append(
        "partnership_deed_file",
        partnershipForm?.partnership_deed
      );
      formValue.append(
        "company_pan_card_file",
        partnershipForm?.company_pan_card
      );
      formValue.append("ffsai_licence_file", partnershipForm?.fssai_licence);
      formValue.append("Domain", domain.host);
    } else {
      if (
        pvtlmtdFormData.email === "" ||
        pvtlmtdFormData.phone_number === "" ||
        pvtlmtdFormData.gst === "" ||
        pvtlmtdFormData.current_account === "" ||
        pvtlmtdFormData.director_pan_card === "" ||
        pvtlmtdFormData.certificate_corporation === "" ||
        pvtlmtdFormData.company_pan_card === "" ||
        pvtlmtdFormData.fssai_licence === ""
      ) {
        return toast.error("All Field required");
      }
      if (
        !maxSizeCheck(pvtlmtdFormData.gst) ||
        !maxSizeCheck(pvtlmtdFormData.current_account) ||
        !maxSizeCheck(pvtlmtdFormData.director_pan_card) ||
        !maxSizeCheck(pvtlmtdFormData.certificate_corporation) ||
        !maxSizeCheck(pvtlmtdFormData.company_pan_card) ||
        !maxSizeCheck(pvtlmtdFormData.fssai_licence)
      ) {
        return toast.error("File should be less then 1mb");
      }

      formValue.append("type", "PvtLmtd");
      formValue.append("email", pvtlmtdFormData?.email);
      formValue.append("phone_number", pvtlmtdFormData?.phone_number);
      formValue.append("gst_file", pvtlmtdFormData?.gst);
      formValue.append(
        "current_account_file",
        pvtlmtdFormData?.current_account
      );
      formValue.append(
        "director_pan_card_file",
        partnershipForm?.director_pan_card
      );
      formValue.append(
        "certificate_incorporation_file",
        partnershipForm?.certificate_corporation
      );
      formValue.append(
        "company_pan_card_file",
        partnershipForm?.company_pan_card
      );
      formValue.append("ffsai_licence_file", partnershipForm?.fssai_licence);
      formValue.append("Domain", domain.host);
    }
    setLoading(true);
    API.payment
      .SendDocument(formValue, token)
      .then((response) => {
        console.log(response);
        if (response) {
          toast.success("Document Sent");
          dispatch(setUser({ ...response }));
          localStorage.setItem("Caledon-Kabab-user", JSON.stringify(response));
          setDocumentSend(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaltKeySubmit = () => {
    const toastId = toast.loading("Loading...");
    API.payment
      .SetSaltKey(saltKeyData, token)
      .then((response) => {
        if (response) {
          toast.success("Salt Key & MId has been set");
          dispatch(setUser({ ...response }));
          localStorage.setItem("Caledon-Kabab-user", JSON.stringify(response));
        }
      })
      .finally(() => {
        toast.dismiss(toastId);
      });
  };

  const _this = {
    loading,
    setLoading,
    formTab,
    setFormTab,
    individualFormData,
    setIndividualFormData,
    partnershipForm,
    setPartnershipForm,
    pvtlmtdFormData,
    setPvtLmtdFormData,
    handleSubmit,
    documentSend,
    setDocumentSend,
    saltKeyData,
    setSaltkeyData,
    handleSaltKeySubmit,
    maxSizeCheck,
  };
  return (
    <Container>
      <Body {..._this} />
    </Container>
  );
};

export default OnlinePayment;
