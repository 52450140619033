const BASE_URL = process.env.REACT_APP_BASE_URL;

// CATEGORY ENDPOINTS
export const categoryEndpoints = {
  GET_ALL_CATEGORY_API: BASE_URL + "/api/v1/category/get-all-category",
  GET_CATEGORY_API: BASE_URL + "/api/v1/category/get-all-category/:category_id",
  CREATE_CATEGORY_API: BASE_URL + "/api/v1/category/create-category",
  UPDATE_CATEGORY_API: BASE_URL + "/api/v1/category/update-category",
};

// AUTH ENDPOINTS
export const authEndpoints = {
  VERIFY_TOKEN_API: BASE_URL + "/api/v1/auth/verify-token",
  SEND_OTP_API: BASE_URL + "/api/v1/auth/sendotp",
  SIGNUP_API: BASE_URL + "/api/v1/auth/signup",
  LOGIN_API: BASE_URL + "/api/v1/auth/login",
  FORGOT_PASSWORD_API: BASE_URL + "/api/v1/auth/forgot-password",
  RESEND_OTP_API: BASE_URL + "/api/v1/auth/resend-otp",
  UPDATE_PHONE_NUMBER: BASE_URL + "/api/v1/auth/update-phone",
  UPDATE_EMAIL: BASE_URL + "/api/v1/auth/update-email",
};

// PRODUCT ENDPOINTS
export const productEndpoints = {
  GET_ALL_PRODUCTS_API: BASE_URL + "/api/v1/products/get-all-products",
  GET_PRODUCTS_BY_CATEGORY_API:
    BASE_URL + "/api/v1/products/get-products-by-category",
  GET_ALL_PRODUCTS_BY_CATEGORY_API:
    BASE_URL + "/api/v1/products/get-all-products-by-category",
  CREATE_PRODUCT_API: BASE_URL + "/api/v1/products/create-product",
  DELETE_PRODUCT_API: BASE_URL + "/api/v1/products/delete-product",
  UPDATE_PRODUCT_API: BASE_URL + "/api/v1/products/update-product",
  UPDATE_PRODUCT_AVAILABILITY_API:
    BASE_URL + "/api/v1/products/update-product-availability",
  UPDATE_UNIVERSAL_AVAILABILITY_API:
    BASE_URL + "/api/v1/products/update-universal-availability",
  UPDATE_PRODUCT_QUANTITY_API:
    BASE_URL + "/api/v1/products/update-product-quantity",
};

// ORDERS ENDPOINTS
export const orderEndpoints = {
  CREATE_ORDER_LOGGED_IN_API: BASE_URL + "/api/v1/order/create-order-logged-in",
  CREATE_ORDER_API: BASE_URL + "/api/v1/order/create-order",
  CREATE_ORDER_REQUEST_API: BASE_URL + "/api/v1/order/create-order-request",
  UPDATE_ORDER_STATUS_API: BASE_URL + "/api/v1/order/update-order-status",
  GET_USER_ORDERS_API: BASE_URL + "/api/v1/order/get-orders",
  GET_ORDERS_ID_API: BASE_URL + "/api/v1/order/get-orders-by-id",
  GET_ADMIN_ORDERS_API: BASE_URL + "/api/v1/order/get-all-orders",
  GET_ORDERS_REQUEST_API: BASE_URL + "/api/v1/order/get-order-request",
  ORDER_ACCEPT_API: BASE_URL + "/api/v1/order/order-accept",
  ORDER_REJECT_API: BASE_URL + "/api/v1/order/order-reject",
};

// ORDERS ADDRESS
export const addressEndpoints = {
  GET_ADDRESS_API: BASE_URL + "/api/v1/address/get-all-address",
  CREATE_ADDRESS_API: BASE_URL + "/api/v1/address/create-address",
  UPDATE_ADDRESS_API: BASE_URL + "/api/v1/address/update-address",
  DELETE_ADDRESS_API: BASE_URL + "/api/v1/address/delete-address",
};

// PAYMENT ENDPOINTS
export const productPaymentEndpoints = {
  PRODUCT_PAYMENT_API: BASE_URL + "/api/v1/payment/capture-payment",
  PRODUCT_VERIFY_API: BASE_URL + "/api/v1/payment/verify-payment",
  PAYMENT_SEND_MAIL_API: BASE_URL + "/api/v1/payment/send-document",
  SET_SALT_KEY_API: BASE_URL + "/api/v1/payment/set-saltkey",
};
