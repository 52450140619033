import React from "react";
import { SlLocationPin } from "react-icons/sl";
import EditAddressSlider from "./EditAddressSlider";
import DeleteAddressModal from "./DeleteAddressModal";
import { MdArrowForwardIos } from "react-icons/md";
import AddAddress from "./AddAddress";

const Body = (_this) => {
  return (
    <>
      <div className="w-full flex justify-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)] bg-black/5">
        <div className="flex flex-col w-full sm:w-10/12 md:w-8/12 lg:w-6/12 max-w-maxContent gap-y-4 py-3">
          <div className="w-full flex flex-col gap-y-3">
            <div className="flex items-center justify-between">
              <span className="text-xs text-black/70 pl-3 uppercase">
                Manage Address
              </span>
              <button
                onClick={() => _this.setAddAddressSlider(true)}
                className="text-sm text-black/70 pr-3 pl-3 py-1 font-semibold bg-primaryColor text-white"
              >
                Add New
              </button>
            </div>
            <div className="h-[1px] bg-black/10 my-1"></div>
            <div className="flex flex-col gap-y-4">
              {_this.addressData.map((address, index) => {
                return (
                  <div key={address._id} className="bg-white py-3 px-4">
                    <div className="flex justify-between items-center">
                      <div className="flex gap-x-2 text-sm text-black/70">
                        <SlLocationPin />
                        <h5>Address {index + 1}</h5>
                      </div>
                      <p className="text-sm text-black/70">
                        {address.address_type}
                      </p>
                    </div>
                    <div className="h-[1px] bg-black/10 my-1"></div>
                    <div className="flex flex-col gap-y-2">
                      <div className="text-xs font-extralight">
                        <p>
                          <span className="font-normal">Phone :</span>{" "}
                          {address.phone_number}
                        </p>
                        <p>
                          <span className="font-normal">Door / Flat :</span>{" "}
                          {address.door_flat}
                        </p>
                        <p>
                          <span className="font-normal">Street :</span>{" "}
                          {address.street}
                        </p>
                        <p>
                          <span className="font-normal">City :</span>{" "}
                          {address.city}
                        </p>
                        <p>
                          <span className="font-normal">State :</span>{" "}
                          {address.state}
                        </p>

                        <p>
                          <span className="font-normal">Landmark :</span>{" "}
                          {address.land_mark}
                        </p>
                        <p>
                          <span className="font-normal">Pin :</span>{" "}
                          {address.postal_code}
                        </p>
                      </div>
                      <div className="flex justify-end gap-x-4">
                        <button
                          onClick={() => {
                            _this.setEditAddressSlider(true);
                            _this.setEditAddressData((prev) => ({
                              ...prev,
                              address_id: address._id,
                              phone_number: address.phone_number,
                              door_flat: address.door_flat,
                              street: address.street,
                              city: address.city,
                              state: address.state,
                              land_mark: address.land_mark,
                              postal_code: address.postal_code,
                              address_type: address.address_type,
                            }));
                          }}
                          className="text-base text-primaryColor font-bold"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => {
                            _this.setDeleteAddressData(address);
                            _this.setDeleteAddressModal(true);
                          }}
                          className="text-base text-primaryColor font-bold"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <AddAddress {..._this} />
      <EditAddressSlider {..._this} />
      <DeleteAddressModal {..._this} />
    </>
  );
};

export default Body;
