import React from "react";

const Body = () => {
  return (
    <div className="flex gap-y-2 min-h-[calc(100vh-100px)] w-[100%] justify-center overflow-x-hidden bg-black/10">
      <div className="h-full w-11/12 max-w-maxContent mx-auto bg-white mt-6 py-4 px-6">
        <div className="flex flex-col items-center gap-y-3 ">
          <div className="text-sm">
            <h2 className="text-lg font-bold mb-4">Refund Policy</h2>
            <p>
              At Caledon Kabab, we want you to be completely satisfied with your
              order. If you're not happy with your purchase, we offer a
              straightforward refund policy.
            </p>
            <p className="mt-2">
              If you believe that your order doesn't meet your expectations,
              please reach out to us within 24 hours of receiving your food.
            </p>
            <p className="mt-2">
              Our team will review your request and may ask for additional
              information or evidence to assess the issue.
            </p>
            <p className="mt-2">
              If the problem is deemed valid and the food quality is found to be
              unsatisfactory, we'll initiate a refund process immediately.
            </p>
            <p className="mt-2">
              Refunds will be processed within 2 working days and will be
              credited back to the original payment method used for the
              purchase.
            </p>
            <p className="mt-4">
              Your satisfaction is important to us, and we appreciate your
              understanding and cooperation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
