import React from "react";

const Payments = (_this) => {
  return (
    <div className="w-11/12 mx-auto overflow-x-hidden bg-white">
      <div className="flex flex-col gap-3 p-4">
        <h4 className="font-semibold text-black/70">Payments</h4>
        <div className="h-[1px] bg-black/5"></div>
        <div className="w-full mx-auto p-5 flex flex-col gap-y-6">
          <div>
            <ul className="grid w-full gap-6 md:grid-cols-2">
              <li>
                <input
                  type="radio"
                  id="online_radio"
                  name="payment_mod"
                  value="Online"
                  className="hidden peer"
                  onChange={(e) => {
                    _this.setPaymetMod(e.target.value);
                  }}
                  checked={_this?.paymentMod === "Online" ? true : false}
                />
                <label
                  htmlFor="online_radio"
                  className="inline-flex items-center justify-between w-full p-3 md:p-4 text-black/65 bg-white border border-black/40 rounded-lg peer-checked:bg-greenColor/5 cursor-pointer peer-checked:border-greenColor peer-checked:text-greenColor"
                >
                  <div className="block">
                    <div className="w-full text-sm md:text-lg md:font-semibold">
                      Pay Online
                    </div>
                  </div>
                </label>
              </li>

              <li>
                <input
                  type="radio"
                  id="cod_radio"
                  name="payment_mod"
                  value="Cod"
                  className="hidden peer"
                  onChange={(e) => {
                    _this.setPaymetMod(e.target.value);
                  }}
                  checked={_this?.paymentMod === "Cod" ? true : false}
                />
                <label
                  htmlFor="cod_radio"
                  className="inline-flex items-center justify-between w-full p-3 md:p-4 text-black/65 bg-white border border-black/40 rounded-lg cursor-pointer peer-checked:bg-greenColor/5 peer-checked:border-greenColor peer-checked:text-greenColor"
                >
                  <div className="block">
                    <div className="w-full text-sm md:text-lg md:font-semibold">
                      Pay on Delivey
                    </div>
                  </div>
                </label>
              </li>
            </ul>
          </div>
          <button
            onClick={() => _this.onOrderPlaced()}
            className="w-full bg-greenColor py-2 text-white font-bold text-base"
          >
            Place order
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payments;
