import React from "react";
import Body from "./Body";
import Navbar from "../../components/Navbar";

const Help = () => {
  const _this = {};
  return (
    <>
      <Navbar />
      <div className="mt-[65px] mb-[60px] md:mb-[0px] md:mt-[90px]">
        <Body {..._this} />
      </div>
    </>
  );
};

export default Help;
