import React, { useState, useEffect } from "react";
import axios from "axios";
import io from "socket.io-client";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Body from "./Body";
import Navbar from "../../components/Navbar";
import { getAllAddress } from "../../services/operations/addressApi";
import API from "../../services/api";
import { getAllProducts } from "../../services/operations/productApi";
import { createOrderforLoggedIn } from "../../services/operations/ordersApi";
import { clearCart } from "../../reducer/slices/cartSlice";
import { setSidebar } from "../../reducer/slices/authSlice";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const socket = io.connect(BASE_URL);

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const { cart_items, total_items, total_amount } = useSelector(
    (state) => state.cart
  );
  const [loading, setLoading] = useState(false);
  const [cartProducts, setCartProducts] = useState(cart_items);
  const [addAddressSlider, setAddAddressSlider] = useState(false);
  const [editAddressSlider, setEditAddressSlider] = useState(false);
  const [addressData, setaddressData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [userData, setUserData] = useState({
    user_name: "",
    phone_number: "",
  });
  const [paymentMod, setPaymetMod] = useState("");
  const [addAddressData, setAddAddressData] = useState({
    phone_number: "",
    door_flat: "",
    street: "",
    city: "",
    state: "",
    land_mark: "",
    postal_code: "",
    address_type: "",
  });
  const [editAddressData, setEditAddressData] = useState({
    address_id: "",
    phone_number: "",
    door_flat: "",
    street: "",
    city: "",
    state: "",
    land_mark: "",
    postal_code: "",
    address_type: "",
  });
  const [orderAddress, setOrderAddress] = useState({
    address_id: "",
    phone_number: "",
    door_flat: "",
    street: "",
    city: "",
    state: "",
    land_mark: "",
    postal_code: "",
  });

  const onOrderPlaced = async () => {
    // handle two condition on is for log in another is non logged in
    if (token === null) return dispatch(setSidebar(true));
    if (
      orderAddress.phone_number === undefined ||
      orderAddress.door_flat === undefined
    ) {
      setEditAddressSlider(true);
      _this.setEditAddressData((prev) => ({
        ...prev,
        address_id: orderAddress.address_id,
        phone_number: orderAddress.phone_number,
        door_flat: orderAddress.door_flat,
        street: orderAddress.street,
        city: orderAddress.city,
        state: orderAddress.state,
        land_mark: orderAddress.land_mark,
        postal_code: orderAddress.postal_code,
        address_type: orderAddress.address_type,
      }));
      return toast.error(
        "Please add phone number and door/flate on selected Address"
      );
    }
    if (
      orderAddress.phone_number === "" ||
      orderAddress.door_flat === "" ||
      orderAddress.street === "" ||
      orderAddress.city === "" ||
      orderAddress.state === "" ||
      orderAddress.land_mark === "" ||
      orderAddress.postal_code === ""
    ) {
      if (token === null) return toast.error("Enter you address");
      else if (token !== null) return toast.error("Please select you address");
    }

    // validation of payment method
    if (paymentMod === "") return toast.error("Please select payment method");
    if (token === null && paymentMod === "Cod")
      return toast.error("Login to order in Cod method");

    let updated_cart = [];
    for (let i = 0; i < cart_items.length; i++) {
      const findProduct = productData.find(
        (item) => item._id === cart_items[i].product._id
      );

      updated_cart.push({
        product: findProduct,
        quantity: cart_items[i].quantity,
      });
    }
    localStorage.setItem("cart_items", JSON.stringify(updated_cart));

    for (let i = 0; i < cart_items.length; i++) {
      const findProduct = productData.find(
        (item) => item._id === cart_items[i].product._id
      );
      if (!findProduct?.is_available)
        return toast.error("Some product are not available");
      else if (
        findProduct.is_available === false ||
        findProduct.product_quantity === 0
      )
        return toast.error("product is not available");
      else if (findProduct.product_quantity < cart_items[i].quantity)
        return toast.error("Product is not availble");
    }
    // order place
    const delivery_charge = 0;
    if (paymentMod === "Cod") {
      const data = {
        order_items: cart_items,
        total_items: total_items,
        total_amount: total_amount + delivery_charge,
        order_address: orderAddress,
        payment_mod: paymentMod,
      };

      const res = await createOrderforLoggedIn(token, data);
      if (res) {
        dispatch(clearCart());
        navigate("/");
        setOrderAddress((prev) => ({
          ...prev,
          street: "",
          city: "",
          state: "",
          land_mark: "",
          postal_code: "",
        }));
      }
      socket.emit("send_order_placed", data);
    } else {
      const data = {
        name: "Caledon Kababs",
        amount: total_amount,
        number: "9475149702",
        MUID: "MUID" + Date.now(),
        transactionId: "T" + Date.now(),
        order_items: cart_items,
        total_items: total_items,
        total_amount: total_amount + delivery_charge,
        order_address: orderAddress,
        payment_mod: paymentMod,
        token: token,
      };
      setLoading(true);
      axios
        .post(`${BASE_URL}/api/v1/payment/capture-payment`, { ...data })
        .then((res) => {
          if (!res?.data?.error) {
            window.location.href = res.data;
          } else toast.error(res?.data?.error);
        })
        .catch((error) => {
          console.error(error);
        });
      setLoading(false);
    }
  };

  const addAddressSubmit = async () => {
    if (addAddressData.phone_number === "")
      return toast.error("Please enter phone number");
    if (addAddressData.door_flat === "")
      return toast.error("Please enter Door / Flat No");
    if (addAddressData.street === "") return toast.error("Please enter street");
    else if (addAddressData.city === "")
      return toast.error("Please enter city");
    else if (addAddressData.state === "")
      return toast.error("Please enter state");
    else if (addAddressData.land_mark === "")
      return toast.error("Please enter landmark");
    else if (addAddressData.postal_code === "")
      return toast.error("Please enter pin code");
    else if (addAddressData.address_type === "")
      return toast.error("Please select address type");

    const formData = new FormData();
    formData.append("phone_number", addAddressData.phone_number);
    formData.append("door_flat", addAddressData.door_flat);
    formData.append("street", addAddressData.street);
    formData.append("city", addAddressData.city);
    formData.append("state", addAddressData.state);
    formData.append("land_mark", addAddressData.land_mark);
    formData.append("postal_code", addAddressData.postal_code);
    formData.append("address_type", addAddressData.address_type);

    API.address
      .CreateAddress(token, formData)
      .then((response) => {
        if (response) {
          setaddressData((prevAddress) => [...prevAddress, response]);
          toast.success("Address Updated");
        }
      })
      .finally(() => {
        setAddAddressData((prev) => ({
          ...prev,
          phone_number: "",
          door_flat: "",
          street: "",
          city: "",
          state: "",
          land_mark: "",
          postal_code: "",
          address_type: "",
        }));
        setAddAddressSlider(false);
      });
  };

  const editAddressSubmit = async () => {
    if (editAddressData.phone_number === "")
      return toast.error("Please enter phone number");
    if (editAddressData.door_flat === "")
      return toast.error("Please enter Door / Flat No");
    if (editAddressData.street === "")
      return toast.error("Please enter street");
    else if (editAddressData.city === "")
      return toast.error("Please enter city");
    else if (editAddressData.state === "")
      return toast.error("Please enter state");
    else if (editAddressData.land_mark === "")
      return toast.error("Please enter landmark");
    else if (editAddressData.postal_code === "")
      return toast.error("Please enter pin code");
    else if (editAddressData.address_type === "") {
      return toast.error("Please select address type");
    }
    const formData = new FormData();
    formData.append("address_id", editAddressData.address_id);
    formData.append("phone_number", editAddressData.phone_number);
    formData.append("door_flat", editAddressData.door_flat);
    formData.append("street", editAddressData.street);
    formData.append("city", editAddressData.city);
    formData.append("state", editAddressData.state);
    formData.append("land_mark", editAddressData.land_mark);
    formData.append("postal_code", editAddressData.postal_code);
    formData.append("address_type", editAddressData.address_type);

    API.address
      .UpdateAddress(token, formData)
      .then((response) => {
        if (response) {
          setaddressData((prevAddresses) =>
            prevAddresses.map((address) =>
              address._id === response._id
                ? { ...address, ...response }
                : address
            )
          );
          toast.success("Address Updated");
          setOrderAddress((prev) => ({
            ...prev,
            address_id: response._id,
            phone_number: response.phone_number,
            door_flat: response.door_flat,
            street: response.street,
            city: response.city,
            state: response.state,
            land_mark: response.land_mark,
            postal_code: response.postal_code,
          }));
        }
      })
      .finally(() => {
        setEditAddressData((prev) => ({
          ...prev,
          door_flat: "",
          street: "",
          city: "",
          state: "",
          land_mark: "",
          postal_code: "",
          address_type: "",
        }));
        setEditAddressSlider(false);
      });
  };

  useEffect(() => {
    getAllAddressOfUser();
  }, [token]);

  useEffect(() => {
    getAllItems();
  }, []);

  const getAllAddressOfUser = async () => {
    setLoading(true);
    try {
      if (token && user.account_type === "Customer") {
        const res = await getAllAddress(token);
        setaddressData(res);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getAllItems = async () => {
    setLoading(true);
    try {
      const res = await getAllProducts();
      setProductData(res);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const _this = {
    loading,
    setLoading,
    paymentMod,
    setPaymetMod,
    orderAddress,
    setOrderAddress,
    onOrderPlaced,
    addressData,
    setaddressData,
    addAddressSlider,
    setAddAddressSlider,
    addAddressData,
    setAddAddressData,
    addAddressSubmit,
    userData,
    setUserData,
    cartProducts,
    setCartProducts,
    editAddressData,
    setEditAddressData,
    editAddressSlider,
    setEditAddressSlider,
    editAddressSubmit,
  };
  return (
    <>
      <Navbar />
      <div className="mt-[65px] mb-[60px] md:mb-[0px] md:mt-[90px]">
        <Body {..._this} />
      </div>
    </>
  );
};

export default Cart;
