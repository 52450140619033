import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "./slices/authSlice";
import profileReducer from "./slices/profileSlice";
import navigationReducer from "./slices/navigationSlice";
import cartReducer from "./slices/cartSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  navigation: navigationReducer,
  cart: cartReducer,
});

export default rootReducer;
