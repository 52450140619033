import React from "react";
import { Empty } from "antd";
import SingleOrder from "./SingleOrder";
const OrdersTable = (_this) => {
  return (
    <div className="relative h-full w-full">
      {_this.ordersData.length === 0 ? (
        <div className="flex flex-col items-center justify-center gap-y-2  min-h-[calc(100vh-200px)]">
          <Empty description={<span>No Orders</span>} />
        </div>
      ) : (
        <div className="flex flex-col gap-y-7">
          {_this.ordersData.map((order) => {
            return (
              <div key={order._id} className="w-full min-w-max">
                <SingleOrder order={order} _this={_this} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OrdersTable;
