import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const CustomerRoutes = ({ children }) => {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  if (token !== null && user.account_type === "Customer") {
    return children;
  } else {
    return <Navigate to="/dashboard/admin" />;
  }
};

export default CustomerRoutes;
