import React from "react";
import { Modal, ConfigProvider } from "antd";
import OtpInput from "react-otp-input";

const EmailVerifyModal = (_this) => {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            borderRadiusLG: 0,
          },
        }}
      >
        <Modal
          open={_this.emailVerifyModal}
          onCancel={() => _this.setEmailVerifyModal(false)}
          centered={true}
          footer={
            <div className="flex justify-end gap-x-3">
              {/* <button
                onClick={() => _this.updatePhoneNumberSubmit()}
                className="border-[2px] p-1.5 border-primaryColor text-primaryColor font-bold"
              >
                Resend
              </button> */}
              <button
                onClick={() => _this.updateEmailSubmit()}
                className="border-[2px] p-1.5 border-primaryColor bg-primaryColor text-white font-bold"
              >
                Verify
              </button>
            </div>
          }
        >
          <div className="flex justify-center items-center min-h-[80px]">
            <form>
              <OtpInput
                value={_this.emailOtp}
                onChange={_this.setEmailOtp}
                numInputs={4}
                renderInput={(props) => (
                  <input
                    {...props}
                    placeholder="-"
                    style={{
                      boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                    }}
                    className="w-[48px] lg:w-[60px] my-4 border border-primaryColor text-black/80 aspect-square text-center focus:border-0 focus:outline-2 focus:outline-primaryColor"
                  />
                )}
                containerStyle={{
                  justifyContent: "space-between",
                  gap: "0 6px",
                }}
              />
            </form>
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default EmailVerifyModal;
