import { Empty } from "antd";
import React, { useState, useEffect } from "react";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import toast from "react-hot-toast";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const AllProducts = (_this) => {
  //Placeholder auto
  const fixedText = "Search for ";
  const dynamicTexts = ["Kababs", "Fish Kababs", "Veg Thali"];
  const [TextIndex, setTextIndex] = useState(0);
  const [typedText, setTypedText] = useState("");

  useEffect(() => {
    let timeoutId;
    let charIndex = 0;
    const typeNextChar = () => {
      setTypedText((prevText) =>
        dynamicTexts[TextIndex].slice(0, charIndex + 1)
      );
      charIndex += 1;

      if (charIndex <= dynamicTexts[TextIndex].length) {
        timeoutId = setTimeout(typeNextChar, 80);
      } else {
        charIndex = 0;
        setTextIndex((prevIndex) => (prevIndex + 1) % dynamicTexts.length);
        timeoutId = setTimeout(typeNextChar, 1500);
      }
    };
    timeoutId = setTimeout(typeNextChar, 1000);
    return () => clearTimeout(timeoutId);
  }, [TextIndex, dynamicTexts.length]);

  const placeholderText = `${fixedText}'${typedText}'`;
  return (
    <div className="flex flex-col gap-y-9">
      <div className="h-full w-full md:w-11/12 flex items-center justify-between">
        <h3 className="text-base sm:text-xl md:text-2xl font-bold text-black/75">
          Explore our Items
        </h3>
        <div></div>
      </div>
      <div>
        {_this.productData.length === 0 ? (
          <div className="pt-16">
            <Empty description={<span>No Items</span>} />
          </div>
        ) : (
          <div className="w-full flex flex-col items-center justify-center gap-y-8">
            <div className="w-full">
              <form className="w-full">
                <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full p-4 ps-10 text-sm font-bold outline-none border border-black/10 bg-grayBackground/30"
                    placeholder={placeholderText}
                    onChange={_this.handleInputChange}
                    required
                  />
                </div>
              </form>
            </div>
            <div className="w-full">
              {_this.suggestions.length === 0 ? (
                <div className="flex mx-auto text-center"></div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-10 w-full">
                  {_this.suggestions.map((product) => {
                    return (
                      <div
                        key={product._id}
                        className="flex justify-center items-center w-full"
                      >
                        <div className="hidden sm:flex flex-col gap-y-2 ">
                          <img
                            alt="product_image"
                            src={`${BASE_URL + product.product_image}`}
                            className={`h-32 object-cover rounded-xl ${
                              (!product.universal_availability ||
                                !product?.is_available) &&
                              "grayscale"
                            }`}
                          />
                          <div className="flex flex-col gap-4">
                            <p>{product.product_name}</p>
                            <div className="flex justify-between">
                              <p className="flex items-center font-semibold">
                                <FaIndianRupeeSign />
                                {product.product_price}
                              </p>
                              {product.universal_availability &&
                              product?.is_available ? (
                                <button
                                  className="border-2 border-primaryColor text-primaryColor font-bold py-1 px-2 flex items-center gap-2"
                                  onClick={() =>
                                    _this.addToCartHandler(product)
                                  }
                                >
                                  <FaPlus />
                                  Add
                                </button>
                              ) : (
                                <p>Not Available</p>
                              )}
                              {product.universal_availabilityy === true}
                            </div>
                          </div>
                        </div>
                        <div className="sm:hidden w-full h-full flex flex-col justify-between">
                          <div className="flex flex-row gap-x-3 w-full">
                            <div className="relative w-44">
                              <img
                                alt="product_image"
                                src={`${BASE_URL + product.product_image}`}
                                className={`h-20 xs:h-24 min-h-24 max-h-24 object-cover rounded-lg ${
                                  (!product.universal_availability ||
                                    !product?.is_available) &&
                                  "grayscale"
                                }`}
                              />
                            </div>
                            <div className="w-full flex flex-col justify-around pr-0.5 pb-1 xs:pr-0 xs:pb-0 xs:flex-row xs:justify-between">
                              <div className="flex flex-col justify-around min-w-20 gap-1">
                                <p className="text-xs xs:text-sm sm:text-base">
                                  {product.product_name}
                                </p>
                                <p className="flex items-center text-xs xs:text-sm">
                                  <FaIndianRupeeSign />
                                  {product.product_price}
                                </p>
                              </div>
                              <button
                                onClick={() =>
                                  product.is_available &&
                                  _this.addToCartHandler(product)
                                }
                                className={`xs:h-full font-bold text-white ${
                                  !product.universal_availability ||
                                  !product?.is_available
                                    ? "bg-black/20"
                                    : "bg-primaryColor/90"
                                }  xs:rounded-tr-md xs:rounded-br-md px-3`}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                          <div className="h-[1px] bg-black/10 mt-4"></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {_this.productData.map((categoryProduct, index) => {
                return (
                  <div
                    key={index}
                    id={categoryProduct.category_id}
                    className="w-full flex flex-col gap-y-6"
                  >
                    <div className="w-full border-b border-black/10 pt-10 pb-2">
                      <h5 className="text-lg font-bold text-black/80">
                        {categoryProduct.category}
                      </h5>
                    </div>
                    {categoryProduct?.products?.length === 0 ? (
                      <div className="pt-16">
                        <Empty description={<span>No Items</span>} />
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-10 w-full">
                        {categoryProduct?.products?.map((product) => {
                          return (
                            <div
                              key={product._id}
                              className="flex justify-center items-center w-full"
                            >
                              <div className="hidden sm:flex flex-col gap-y-2 ">
                                <img
                                  alt="product_image"
                                  src={`${BASE_URL + product.product_image}`}
                                  className={`h-32 object-cover rounded-xl ${
                                    (!product.universal_availability ||
                                      !product?.is_available) &&
                                    "grayscale"
                                  }`}
                                />
                                <div className="flex flex-col gap-4">
                                  <div className="flex flex-col gap-y-1 ">
                                    <p className="text-sm text-wrap">
                                      {product.product_name}
                                    </p>
                                    <p
                                      onClick={() => {
                                        _this?.setProductDetailsModal(product);
                                      }}
                                      className="text-xs cursor-pointer text-blue-100"
                                    >
                                      Show Details
                                    </p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p className="flex items-center font-semibold">
                                      <FaIndianRupeeSign />
                                      {product.product_price}
                                    </p>
                                    {product.universal_availability &&
                                    product?.is_available ? (
                                      <button
                                        className="border-2 border-primaryColor text-primaryColor font-bold py-1 px-2 flex items-center gap-2"
                                        onClick={() =>
                                          _this.addToCartHandler(product)
                                        }
                                      >
                                        <FaPlus />
                                        Add
                                      </button>
                                    ) : (
                                      <p>Not Available</p>
                                    )}
                                    {product.universal_availabilityy === true}
                                  </div>
                                </div>
                              </div>
                              <div className="sm:hidden w-full h-full flex flex-col justify-between">
                                <div className="flex flex-row gap-x-3 w-full">
                                  <div className="relative w-44">
                                    <img
                                      alt="product_image"
                                      src={`${
                                        BASE_URL + product.product_image
                                      }`}
                                      className={`h-20 xs:h-24 min-h-24 max-h-24 object-cover rounded-lg ${
                                        (!product.universal_availability ||
                                          !product?.is_available) &&
                                        "grayscale"
                                      }`}
                                    />
                                  </div>
                                  <div className="w-full flex flex-col justify-around pr-0.5 pb-1 xs:pr-0 xs:pb-0 xs:flex-row xs:justify-between">
                                    <div className="flex flex-col justify-around min-w-20 gap-1">
                                      <div className="flex flex-col gap-1">
                                        <p className="text-xs xs:text-sm sm:text-base">
                                          {product.product_name}
                                        </p>
                                        <p
                                          onClick={() => {
                                            _this?.setProductDetailsModal(
                                              product
                                            );
                                          }}
                                          className="text-xs cursor-pointer text-blue-100"
                                        >
                                          Show Details
                                        </p>
                                      </div>
                                      <p className="flex items-center text-xs xs:text-sm">
                                        <FaIndianRupeeSign />
                                        {product.product_price}
                                      </p>
                                    </div>
                                    <button
                                      onClick={() => {
                                        product.is_available &&
                                          product.universal_availability &&
                                          _this.addToCartHandler(product);
                                      }}
                                      className={`xs:h-full font-bold text-white ${
                                        !product.universal_availability ||
                                        !product?.is_available
                                          ? "bg-black/20"
                                          : "bg-primaryColor/90"
                                      }  xs:rounded-tr-md xs:rounded-br-md px-3`}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                                <div className="h-[1px] bg-black/10 mt-4"></div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllProducts;
