import { apiConnector, handleResponse } from "./core";
import { productPaymentEndpoints } from "../apis";

const { PAYMENT_SEND_MAIL_API, SET_SALT_KEY_API } = productPaymentEndpoints;

const payment = {
  SendDocument: async (data, token) => {
    let response = null;
    try {
      response = await apiConnector("POST", PAYMENT_SEND_MAIL_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  SetSaltKey: async (data, token) => {
    let response = null;
    try {
      response = await apiConnector("POST", SET_SALT_KEY_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
};

export default payment;
