import { apiConnector, handleResponse } from "./core";
import { categoryEndpoints, productEndpoints } from "../apis";

const {
  GET_ALL_CATEGORY_API,
  GET_CATEGORY_API,
  CREATE_CATEGORY_API,
  UPDATE_CATEGORY_API,
} = categoryEndpoints;

const category = {
  GetAllCategory: async (data) => {
    let response = null;
    try {
      response = await apiConnector("GET", GET_ALL_CATEGORY_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
};

export default category;
