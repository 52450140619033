import React from "react";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus, FaMinus } from "react-icons/fa";
import {
  increaseItemQuantity,
  decreaseItemQuantity,
} from "../../reducer/slices/cartSlice";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const CartItems = (_this) => {
  const dispatch = useDispatch();
  const { cart_items } = useSelector((state) => state.cart);
  return (
    <div className="flex flex-col gap-y-4 w-11/12 mx-auto bg-white p-2 md:p-4">
      {cart_items.map((items) => {
        return (
          <div
            className=" w-full flex flex-col justify-between "
            key={items.product._id}
          >
            <div className="flex flex-row gap-x-3 w-full">
              <img
                alt="product_image"
                src={`${BASE_URL + items.product.product_image}`}
                className={`h-14 xs:h-16 max-h-20 object-cover rounded-md ${
                  !items.product.is_available && "grayscale"
                }`}
              />
              <div className="w-full flex flex-col justify-around pr-0.5 pb-1 xs:pr-0 xs:pb-0 xs:flex-row xs:justify-between">
                <div className="flex w-full flex-col justify-around min-w-20 gap-1">
                  <p className="text-xs sm:text-sm">
                    {items.product.product_name}
                  </p>
                  <p className="flex items-center text-xs sm:text-sm font-light">
                    <LiaRupeeSignSolid />
                    {items.product.product_price}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-between pr-2 xs:justify-center xs:w-max gap-2  md:gap-1 w-full">
                  <button
                    onClick={() =>
                      dispatch(decreaseItemQuantity(items.product))
                    }
                    className=" text-base text-greenColor"
                  >
                    <FaMinus />
                  </button>
                  <p className="text-xs sm:text-sm font-semibold flex">
                    {items.quantity}
                  </p>
                  <button
                    onClick={() =>
                      dispatch(increaseItemQuantity(items.product))
                    }
                    className=" text-base text-greenColor"
                  >
                    <FaPlus />
                  </button>
                </div>
              </div>
            </div>
            <div className="h-[1px] bg-black/5 mt-4"></div>
          </div>
        );
      })}
    </div>
  );
};

export default CartItems;
