import React, { useState, useEffect } from "react";
import Body from "./Body";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Container } from "../../components/DashbordSideBar";
import { getAllOrderAdmin } from "../../services/operations/ordersApi";
import API from "../../services/api";

const Dashbord = () => {
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [allOrdersData, setAllOrdersData] = useState([]);
  const [orderDataByDate, setOrderDataByDate] = useState({});
  const [orderGraphData, setOrderGraphData] = useState({});

  const [startDate, setStartDate] = useState(formatTodayDate());
  const [endDate, setEndDate] = useState(formatTodayDate());

  function formatTodayDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const getOrders = async () => {
    setLoading(true);
    try {
      const res = await getAllOrderAdmin(token);
      const merged = [
        ...res.userActiveOrders,
        ...res.userCancelledOrders,
        ...res.userCompletedOrders,
      ];
      setAllOrdersData(merged);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getProducts = async () => {
    setLoading(true);
    API.product
      .GetAllProducts()
      .then((response) => {
        if (response) {
          setProductData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getOrdersByMonth = () => {
    const orderCountsinMonth = Array.from({ length: 12 }, () => 0);
    const orderAmountinMonth = Array.from({ length: 12 }, () => 0);

    allOrdersData?.filter((order) => {
      const orderData = new Date(order.order_date);
      const month = orderData.getMonth();

      orderCountsinMonth[month]++;
      if (order.order_status === "Completed") {
        orderAmountinMonth[month] += order.total_amount;
      }
    });

    setOrderGraphData({
      orderCountsinMonth,
      orderAmountinMonth,
    });
  };

  const getOrdersSummaryBetweenDates = (startDate, endDate) => {
    const sd = new Date(startDate);
    const ed = new Date(endDate + "T23:59:59");
    const filteredOrders = allOrdersData.filter((order) => {
      const orderDate = order.order_date;
      const parsedDate = new Date(orderDate);
      const ISTOffset = 5.5 * 60 * 60 * 1000;
      const ISTDate = new Date(parsedDate.getTime() + ISTOffset);
      if (ISTDate >= sd && ISTDate <= ed) {
        return true;
      }
    });

    const completedOrders = filteredOrders.filter(
      (order) => order.order_status === "Completed"
    );
    const liveOrders = filteredOrders.filter(
      (order) =>
        order.order_status !== "Cancelled" && order.order_status !== "Completed"
    );
    const cancelledOrders = filteredOrders.filter(
      (order) => order.order_status === "Cancelled"
    );

    const totalCompletedAmount = completedOrders.reduce(
      (total, order) => total + order.total_amount,
      0
    );
    const totalCompletedOrders = completedOrders.length;
    const totalLiveOrders = liveOrders.length;
    const totalCancelledOrders = cancelledOrders.length;
    const summary = {
      totalLiveOrders,
      totalCancelledOrders,
      totalCompletedOrders,
      totalCompletedAmount,
      totalOrders: filteredOrders.length === 0 ? [] : filteredOrders,
      totalOrdersCount: filteredOrders.length,
    };
    setOrderDataByDate(summary);
  };

  const handleLoadData = () => {
    if (startDate === null) {
      return toast.error("Add start Date");
    }
    if (endDate === null) {
      return toast.error("Add end Date");
    }
    getOrdersSummaryBetweenDates(startDate, endDate);
  };

  useEffect(() => {
    getOrders();
    getProducts();
  }, []);

  useEffect(() => {
    getOrdersByMonth();
    getOrdersSummaryBetweenDates(startDate, endDate);
  }, [allOrdersData]);

  const _this = {
    loading,
    setLoading,
    productData,
    setProductData,
    allOrdersData,
    setAllOrdersData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleLoadData,
    orderDataByDate,
    setOrderDataByDate,
    orderGraphData,
    setOrderGraphData,
    formatTodayDate,
    startDate,
    endDate,
  };
  return (
    <Container>
      <Body _this={_this} />
    </Container>
  );
};
export default Dashbord;
