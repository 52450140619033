import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";

import signupformImage from "../../assets/images/signupform.webp";

import { setStep } from "../../reducer/slices/authSlice";

export const SignupForm = (_this) => {
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex justify-between">
        <div>
          <h2 className="text-3xl font-semibold">Sign Up</h2>
          <p className=" text-md mb-4">
            or
            <span
              className="text-primaryColor cursor-pointer"
              onClick={() => dispatch(setStep(1))}
            >
              {" "}
              Already have an account
            </span>
          </p>
          <div className="bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200 h-1 w-8"></div>
        </div>
        <div className="w-24 h-24 flex justify-center items-center bg-backgroundColor rounded-full">
          <div className="w-16 h-16 bg-shadowColor rounded-full">
            <img src={signupformImage} alt="signup" />
          </div>
        </div>
      </div>
      <div>
        {/* Form */}
        <form
          onSubmit={_this.signUpSubmit}
          className="flex w-full flex-col gap-y-4"
        >
          <div className="grid md:grid-cols-2 md:gap-6 gap-y-4">
            <label>
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
                First Name <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="text"
                name="first_name"
                value={_this.signupData.first_name}
                onChange={(e) => {
                  _this.setSignUpData((prev) => ({
                    ...prev,
                    first_name: e.target.value,
                  }));
                }}
                placeholder="Enter first name"
                className="w-[100%] shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
              />
            </label>
            <label>
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
                Last Name <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="text"
                name="last_name"
                value={_this.signupData.last_name}
                onChange={(e) => {
                  _this.setSignUpData((prev) => ({
                    ...prev,
                    last_name: e.target.value,
                  }));
                }}
                placeholder="Enter last name"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
              />
            </label>
          </div>
          <label className="w-full">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
              Email <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type="text"
              name="email"
              value={_this.signupData.email}
              onChange={(e) => {
                _this.setSignUpData((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
              placeholder="Enter email"
              className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
            />
          </label>
          <div className="grid md:grid-cols-2 md:gap-6 gap-y-4">
            <label className="relative">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
                Create Password <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type={_this.showPassword ? "text" : "password"}
                name="password"
                value={_this.signupData.password}
                onChange={(e) => {
                  _this.setSignUpData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }));
                }}
                placeholder="Enter Password"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
              />
              <span
                onClick={() => _this.setShowPassword((prev) => !prev)}
                className="absolute right-3 top-[38px] z-[10] cursor-pointer"
              >
                {_this.showPassword ? (
                  <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                ) : (
                  <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                )}
              </span>
            </label>
            <label className="relative">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
                Confirm Password <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type={_this.showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                value={_this.signupData.confirm_password}
                onChange={(e) => {
                  _this.setSignUpData((prev) => ({
                    ...prev,
                    confirm_password: e.target.value,
                  }));
                }}
                placeholder="Confirm Password"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
              />
              <span
                onClick={() => _this.setShowConfirmPassword((prev) => !prev)}
                className="absolute right-3 top-[38px] z-[10] cursor-pointer"
              >
                {_this.showConfirmPassword ? (
                  <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                ) : (
                  <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                )}
              </span>
            </label>
          </div>
          <div className="w-full flex justify-center pl-4 text-xs font-extralight text-fontFamily">
            <ul className="list-disc">
              <li>The password must contain at least one lowercase letter</li>
              <li>The password must contain at least one uppercase letter</li>
              <li>
                The password must be between 8 and 24 characters in length
              </li>
              <li>The password must contain at least one digit </li>
              <li>
                The password must contain at least one special character among
              </li>
            </ul>
          </div>
          <button
            type="submit"
            className="mt-6 rounded-[8px] bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200 py-[8px] px-[12px] font-medium text-white shadow-xl shadow-shadowColor"
          >
            Create Account
          </button>
        </form>
      </div>
    </div>
  );
};
