import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import loginfromImage from "../../assets/images/logoform.webp";
import { useDispatch } from "react-redux";

// import { login } from "../../../services/operations/authApi";
import { setStep, setSidebar } from "../../reducer/slices/authSlice";

export const LoginForm = (_this) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const forgotPassword = () => {
    dispatch(setSidebar(false));
    navigate("/forgot-password");
  };
  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex justify-between">
        <div>
          <h2 className="text-3xl font-semibold">Login</h2>
          <p className=" text-md mb-4">
            or
            <span
              className="text-primaryColor cursor-pointer"
              onClick={() => dispatch(setStep(2))}
            >
              {" "}
              create an account
            </span>
          </p>
          <div className="bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200 h-1 w-8"></div>
        </div>
        <div className="w-24 h-24 flex justify-center items-center bg-backgroundColor rounded-full">
          <div className="w-16 h-16 bg-shadowColor rounded-full">
            <img src={loginfromImage} alt="login" />
          </div>
        </div>
      </div>
      <form
        onSubmit={_this.loginSubmit}
        className="flex w-full flex-col gap-y-4"
      >
        <label className="w-full">
          <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
            Email or Phone Number <sup className="text-pink">*</sup>
          </p>
          <input
            required
            type="text"
            name="user"
            value={_this.user}
            onChange={(e) => {
              _this.setloginData((prev) => ({ ...prev, user: e.target.value }));
            }}
            placeholder="Enter email or phone number"
            className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
          />
        </label>
        <label className="relative">
          <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
            Password <sup className="text-pink">*</sup>
          </p>
          <input
            required
            type={_this.showPassword ? "text" : "password"}
            name="password"
            value={_this.password}
            onChange={(e) => {
              _this.setloginData((prev) => ({
                ...prev,
                password: e.target.value,
              }));
            }}
            placeholder="Enter Password"
            className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
          />
          <span
            onClick={() => _this.setShowPassword((prev) => !prev)}
            className="absolute right-3 top-[38px] z-[10] cursor-pointer"
          >
            {_this.showPassword ? (
              <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
            ) : (
              <AiOutlineEye fontSize={24} fill="#AFB2BF" />
            )}
          </span>
          <p
            onClick={() => forgotPassword()}
            className="mt-3 ml-auto max-w-max text-xs text-primaryColor hover:underline"
          >
            Forgot Password
          </p>
        </label>
        <button
          type="submit"
          className="mt-6 rounded-[8px] bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200 py-[8px] px-[12px] font-medium text-white shadow-xl shadow-shadowColor"
        >
          Sign In
        </button>
      </form>
    </div>
  );
};
