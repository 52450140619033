import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setCollapsed,
  setActiveRoute,
} from "../../reducer/slices/navigationSlice";
import { Menu, ConfigProvider } from "antd";
import {
  MdClose,
  MdPayment,
  MdOutlineCategory,
  MdOutlineFastfood,
} from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { PiHandbagSimple } from "react-icons/pi";
import {
  AiOutlineTeam,
  AiOutlineMenuUnfold,
  AiOutlineMenuFold,
  AiOutlineHome,
} from "react-icons/ai";
import { MdLogout } from "react-icons/md";

import logo from "../../assets/logo/logo.png";
import LogoutModal from "./LogoutModal";

const Container = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { collapsed, activeRoute } = useSelector((state) => state.navigation);
  const [logoutModal, setLogoutModal] = useState(false);
  const location = useLocation();
  dispatch(setActiveRoute(location.pathname));
  useEffect(() => {
    if (collapsed == null) {
      if (window.innerWidth > 768) dispatch(setCollapsed(false));
      else dispatch(setCollapsed(true));
    }
  }, []);

  const onMenuClick = ({ key }) => {
    if (key === "Logout") {
      setLogoutModal(true);
    } else {
      navigate(key);
      dispatch(setActiveRoute(key));
      if (window.innerWidth && window.innerWidth < 768)
        dispatch(setCollapsed(true));
    }
  };

  const toggleCollapsed = () => {
    dispatch(setCollapsed(!collapsed));
  };

  const items = [
    {
      key: "/dashboard/admin",
      icon: <RxDashboard className="py-0" />,
      children: "",
      label: "Dashboard",
      type: "",
    },
    {
      key: "/dashboard/admin/orders",
      icon: <PiHandbagSimple className="py-0" />,
      label: "Orders",
      type: "",
      // children: [
      //   {
      //     key: "/dashboard/admin/order-request",
      //     label: "Order Request",
      //   },
      //   {
      //     key: "/dashboard/admin/orders",
      //     label: "Live Orders",
      //   },
      // ],
    },
    {
      key: "/dashboard/admin/add-category",
      icon: <MdOutlineCategory className="py-0" />,
      children: "",
      label: "Category",
      type: "",
    },
    {
      key: "/dashboard/admin/add-products",
      icon: <MdOutlineFastfood className="py-0" />,
      children: "",
      label: "Products",
      type: "",
    },
    {
      key: "/dashboard/admin/online-payments",
      icon: <MdPayment className="py-0" />,
      children: "",
      label: "Online Payment",
      type: "",
    },
    {
      key: "/dashboard/admin/manage-teams",
      icon: <AiOutlineTeam className="py-0" />,
      children: "",
      label: "Manage Teams",
      type: "",
    },
    {
      key: "Logout",
      icon: <MdLogout className="py-0" />,
      children: "",
      label: "Logout",
      type: "",
    },
  ];

  const _this = {
    logoutModal,
    setLogoutModal,
  };

  return (
    <>
      <div className="flex flex-1 min-h-screen flex-row relative bg-background">
        <div
          className={`bg-white relative flex flex-col header_nav_profile menu ${
            collapsed ? "lg:w-20 w-0" : "lg:w-56 w-screen"
          } `}
          style={{
            transition: "width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s",
          }}
        >
          <div
            className="absolute cursor-pointer top-4 right-4 lg:hidden flex justify-center items-center w-10 h-10 text-xl z-50"
            onClick={toggleCollapsed}
          >
            <MdClose />
          </div>

          <div className="h-32 border-r border-black/10">
            <div
              className={`pt-8 flex flex-col justify-center items-center transition-all`}
            >
              <div className={`flex items-center text-center transition-all`}>
                <img className="h-12 rounded-md" src={logo} alt="logo" />
              </div>
            </div>
          </div>
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  /* here is your component tokens */
                  itemSelectedColor: "#FF9F00",
                  itemSelectedBg: "#FFEFCC",
                },
              },
            }}
          >
            <Menu
              onClick={onMenuClick}
              className={`flex-1 bg-white border-[0px] ${
                collapsed ? "lg:w-20 w-0" : "lg:w-56 w-screen"
              }`}
              selectedKeys={[activeRoute]}
              defaultSelectedKeys={[activeRoute]}
              mode="inline"
              inlineCollapsed={collapsed}
              items={items}
            />
          </ConfigProvider>
        </div>
        <div
          className={`lg:flex-1 transition-all ${
            collapsed ? "w-full" : "w-0"
          } bg-white shadow-md`}
        >
          <div className="flex flex-col justify-center border-b border-black/5 w-full px-0">
            <div className="flex flex-row justify-between items-center pl-2 pr-10 py-4">
              <div
                className="ml-1 mr-10 cursor-pointer"
                onClick={toggleCollapsed}
              >
                {collapsed ? (
                  <AiOutlineMenuUnfold className="text-xl text-blue-800" />
                ) : (
                  <AiOutlineMenuFold className="text-xl text-blue-800" />
                )}
              </div>
              <div
                className={`flex items-center cursor-pointer hover:text-primaryColor`}
              >
                <div className={`pl-2 transition-all `}>
                  <Link
                    to="/"
                    className={`text-xl leading-normal flex flex-col items-center gap-x-2`}
                  >
                    <AiOutlineHome />
                    <p className="text-sm">Home</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {props.children}
        </div>
      </div>
      <LogoutModal {..._this} />
    </>
  );
};

export { Container };
