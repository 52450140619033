import { apiConnector, handleResponse } from "./core";
import { productEndpoints } from "../apis";

const {
  GET_ALL_PRODUCTS_API,
  GET_PRODUCTS_BY_CATEGORY_API,
  GET_ALL_PRODUCTS_BY_CATEGORY_API,
  CREATE_PRODUCT_API,
  DELETE_PRODUCT_API,
  UPDATE_PRODUCT_API,
  UPDATE_PRODUCT_AVAILABILITY_API,
  UPDATE_UNIVERSAL_AVAILABILITY_API,
  UPDATE_PRODUCT_QUANTITY_API,
} = productEndpoints;

const product = {
  GetAllProducts: async (data) => {
    let response = null;
    try {
      response = await apiConnector("GET", GET_ALL_PRODUCTS_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  GetAllProductsbyCategory: async (data) => {
    let response = null;
    try {
      response = await apiConnector(
        "GET",
        GET_ALL_PRODUCTS_BY_CATEGORY_API,
        data
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
};

export default product;
