import { useEffect, useState, useCallback } from "react";
import OtpInput from "react-otp-input";
import { RxCountdownTimer } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  setResendOtpCount,
  setStep,
  setToken,
} from "../../reducer/slices/authSlice";
import { setUser } from "../../reducer/slices/profileSlice";
import toast from "react-hot-toast";
import API from "../../services/api";

export const OtpForm = () => {
  const { signupData, loading, resendOtpCount } = useSelector(
    (state) => state.auth
  );
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(resendOtpCount);

  const timeOutCallback = useCallback(() => {
    setTimer((currTimer) => currTimer - 1);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  useEffect(() => {
    dispatch(setResendOtpCount(timer));
  }, [timer]);

  const resetTimer = function () {
    if (timer <= 0) {
      setTimer(60);
      dispatch(setResendOtpCount(60));
    }
  };

  const resendOtp = () => {
    if (timer <= 0) {
      const data = {
        first_name: signupData?.first_name,
        last_name: signupData?.last_name,
        email: signupData?.email,
      };
      const toastID = toast.loading("Loading...");
      API.auth
        .SendOtp(data)
        .then((response) => {
          if (response) {
            toast.success("OTP has been set to your email account.");
          }
        })
        .finally(() => {
          resetTimer();
          toast.dismiss(toastID);
        });
    } else {
      return toast.error("Send otp after timer complete");
    }
  };

  // console.log(signupData);

  const handleVerifyAndSignup = (e) => {
    e.preventDefault();
    if (otp === "") toast.error("Please enter Otp");
    const account_type = "Customer";
    const data = {
      ...signupData,
      otp,
      account_type,
    };
    const toastID = toast.loading("Loading...");
    API.auth
      .SignUp(data)
      .then((response) => {
        if (response) {
          dispatch(setToken(response.token));
          dispatch(setUser({ ...response.userData }));
          localStorage.setItem(
            "Caledon-Kabab-token",
            JSON.stringify(response.token)
          );
          localStorage.setItem(
            "Caledon-Kabab-user",
            JSON.stringify(response.userData)
          ); // important
          dispatch(setStep(1));
          toast.success("Signup Successful");
        }
      })
      .finally(() => {
        toast.dismiss(toastID);
      });
  };
  return (
    <div className="flex justify-center items-center">
      {loading ? (
        <div>
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="max-w-[500px] p-4 lg:p-8 flex flex-col gap-y-3">
          <h1 className="text-richblack-5 font-semibold text-[1.875rem] leading-[2.375rem]">
            Verify Email
          </h1>
          <p className="bg-gradient-to-b from-yellow-100 via-yellow-100 to-yellow-200 text-transparent bg-clip-text font-bold">
            A verification code has been sent to you. Enter the code below
          </p>
          <form onSubmit={handleVerifyAndSignup}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderInput={(props) => (
                <input
                  {...props}
                  placeholder="-"
                  style={{
                    boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                  }}
                  className="w-[48px] lg:w-[60px] border border-yellow-50 rounded-[0.5rem] text-richblack-5 aspect-square text-center focus:border-0 focus:outline-2 focus:outline-yellow-100"
                />
              )}
              containerStyle={{
                justifyContent: "space-between",
                gap: "0 6px",
              }}
            />
            <button
              type="submit"
              className="w-full mt-6 rounded-[8px] bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200 py-[8px] px-[12px] font-medium text-white shadow-xl shadow-shadowColor"
            >
              Verify Email
            </button>
          </form>
          <div className="mt-8 flex items-center gap-x-1">
            {/* <button
              className="text-subtext flex items-center gap-x-2"
              onClick={dispatch(setStep(2))}
            >
              <BiArrowBack /> Back To Signup
            </button> */}
            <button
              className={`flex items-center ${
                timer > 0 ? "text-black/30" : "text-blue-100"
              }  gap-x-2`}
              onClick={() => {
                resendOtp();
              }}
            >
              <RxCountdownTimer />
              Resend it
            </button>
            {timer > 0 && (
              <span className="text-sm text-blue-100">
                {"( "}
                {timer}
                {" )"}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
