import React from "react";
import { useSelector } from "react-redux";
import { LiaRupeeSignSolid } from "react-icons/lia";
const Bills = () => {
  const { total_amount, total_items } = useSelector((state) => state.cart);
  return (
    <div className="w-11/12 mx-auto bg-white">
      <div className="p-4 text-xs sm:text-base">
        <div>
          <h4 className="font-semibold text-black/70 pb-2">Bill Details</h4>
          <div className="h-[1px] bg-black/5"></div>
        </div>
        <div className="pt-1 font-light text-black/70">
          <p className="flex justify-between">
            Total Items: <span>{total_items}</span>
          </p>
          <p className="flex justify-between">
            Item Total:{" "}
            <span className="flex items-center gap-x-0.5">
              <LiaRupeeSignSolid />
              {total_amount}
            </span>
          </p>
          <p className="flex justify-between pb-3">
            Delivey Fee:{" "}
            <span className="flex items-center gap-x-0.5">
              <LiaRupeeSignSolid />
              {0}
            </span>
          </p>
          <div className="h-[1px] bg-black/10"></div>
        </div>
        <div className="pt-3">
          <p className="flex justify-between font-semibold  md:text-base text-black/70">
            Total Pay :{" "}
            <span className="flex items-center gap-x-0.5">
              <LiaRupeeSignSolid />
              {total_amount + 0}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Bills;
