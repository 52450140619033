import { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import AddCategory from "./pages/AddCategory";
import Orders from "./pages/Orders";
import AddProduct from "./pages/AddProducts";
import ManageTeams from "./pages/ManageTeams";
import AdminRoutes from "./components/RoutesChecking/AdminRoutes";
import Cart from "./pages/Cart";
import CustomerDashboard from "./pages/CustomerDashboard";
import ManageAddress from "./pages/CustomerMangeAddress";
import CustomerEditProfile from "./pages/CustomerEditProfile";
import CustomerOrders from "./pages/CustomerOrders";
import CustomerOrderById from "./pages/CustomerOrderById";
import CategoryProducts from "./pages/CategoryProducts";
import OrderSuccess from "./pages/OrderSuccess";
import PreLoader from "./components/Preloader";
import CustomerRoutes from "./components/RoutesChecking/CustomerRoutes";
import Help from "./pages/Help";
import PublicRoutes from "./components/RoutesChecking/PublicRoutes";
import OnlinePayment from "./pages/OnlinePayment";
import ForgotPassword from "./pages/ForgotPassword";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyAndPolicy from "./pages/PrivacyAndPolicy";
import ShippingPolicy from "./pages/ ShippingPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import OrderRequest from "./pages/OrderRequest";

const App = () => {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 2000);
  return (
    <>
      {loading && <PreLoader />}
      <div className="flex min-h-screen max-w-screen scroll-smooth flex-col">
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/" element={<Home />} />
          <Route path="/help" element={<Help />} />
          <Route
            path="/products/category/:category_id"
            element={<CategoryProducts />}
          />
          <Route
            path="/cart"
            element={
              <PublicRoutes>
                <Cart />
              </PublicRoutes>
            }
          />
          <Route
            path="/customer/account"
            element={
              <CustomerRoutes>
                <CustomerDashboard />
              </CustomerRoutes>
            }
          />
          <Route
            path="/customer/account/manage-address"
            element={
              <CustomerRoutes>
                <ManageAddress />
              </CustomerRoutes>
            }
          />
          <Route
            path="/customer/account/edit-profile"
            element={
              <CustomerRoutes>
                <CustomerEditProfile />
              </CustomerRoutes>
            }
          />
          <Route
            path="/customer/account/orders"
            element={
              <CustomerRoutes>
                <CustomerOrders />
              </CustomerRoutes>
            }
          />
          <Route
            path="/customer/accounts/order/:order_id"
            element={
              <CustomerRoutes>
                <CustomerOrderById />
              </CustomerRoutes>
            }
          />
          <Route
            path="/order/success"
            element={
              <CustomerRoutes>
                <OrderSuccess />
              </CustomerRoutes>
            }
          />
          {/* Admin Route  */}
          <Route
            path="/dashboard/admin"
            element={
              <AdminRoutes>
                <Dashboard />
              </AdminRoutes>
            }
          />
          {/* <Route
            path="/dashboard/admin/order-request"
            element={
              <AdminRoutes>
                <OrderRequest />
              </AdminRoutes>
            }
          /> */}
          <Route
            path="/dashboard/admin/orders"
            element={
              <AdminRoutes>
                <Orders />
              </AdminRoutes>
            }
          />
          <Route
            path="/dashboard/admin/add-category"
            element={
              <AdminRoutes>
                <AddCategory />
              </AdminRoutes>
            }
          />
          <Route
            path="/dashboard/admin/add-products"
            element={
              <AdminRoutes>
                <AddProduct />
              </AdminRoutes>
            }
          />
          <Route
            path="/dashboard/admin/online-payments"
            element={
              <AdminRoutes>
                <OnlinePayment />
              </AdminRoutes>
            }
          />
          <Route
            path="/dashboard/admin/manage-teams"
            element={
              <AdminRoutes>
                <ManageTeams />
              </AdminRoutes>
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default App;
