import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Navbar from "../../components/Navbar";
import Body from "./Body";
import API from "../../services/api";

const ManageAddress = () => {
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [addressData, setaddressData] = useState([]);
  const [deleteAddressModal, setDeleteAddressModal] = useState(false);
  const [deleteAddressData, setDeleteAddressData] = useState("");
  const [editAddressSlider, setEditAddressSlider] = useState(false);
  const [addAddressSlider, setAddAddressSlider] = useState(false);
  const [editAddressData, setEditAddressData] = useState({
    phone_number: "",
    door_flat: "",
    street: "",
    city: "",
    state: "",
    land_mark: "",
    postal_code: "",
    address_type: "",
  });
  const [addAddressData, setAddAddressData] = useState({
    phone_number: "",
    door_flat: "",
    street: "",
    city: "",
    state: "",
    land_mark: "",
    postal_code: "",
    address_type: "",
  });

  const editAddressSubmit = async () => {
    if (editAddressData.phone_number === "")
      return toast.error("Please enter phone number");
    if (editAddressData.door_flat === "")
      return toast.error("Please enter Door / Flat No");
    if (editAddressData.street === "")
      return toast.error("Please enter street");
    else if (editAddressData.city === "")
      return toast.error("Please enter city");
    else if (editAddressData.state === "")
      return toast.error("Please enter state");
    else if (editAddressData.land_mark === "")
      return toast.error("Please enter landmark");
    else if (editAddressData.postal_code === "")
      return toast.error("Please enter pin code");
    else if (editAddressData.address_type === "") {
      return toast.error("Please select address type");
    }
    const formData = new FormData();
    formData.append("address_id", editAddressData.address_id);
    formData.append("phone_number", editAddressData.phone_number);
    formData.append("door_flat", editAddressData.door_flat);
    formData.append("street", editAddressData.street);
    formData.append("city", editAddressData.city);
    formData.append("state", editAddressData.state);
    formData.append("land_mark", editAddressData.land_mark);
    formData.append("postal_code", editAddressData.postal_code);
    formData.append("address_type", editAddressData.address_type);
    // const res = await updateAddress(token, formData);
    API.address
      .UpdateAddress(token, formData)
      .then((response) => {
        if (response) {
          console.log(response);
          setaddressData((prevAddresses) =>
            prevAddresses.map((address) =>
              address._id === response._id
                ? { ...address, ...response }
                : address
            )
          );
          toast.success("Address Updated");
        }
      })
      .finally(() => {
        setEditAddressData((prev) => ({
          ...prev,
          door_flat: "",
          street: "",
          city: "",
          state: "",
          land_mark: "",
          postal_code: "",
          address_type: "",
        }));
        setEditAddressSlider(false);
      });
  };

  const addAddressSubmit = async () => {
    if (addAddressData.phone_number === "")
      return toast.error("Please enter phone number");
    if (addAddressData.door_flat === "")
      return toast.error("Please enter Door / Flat No");
    if (addAddressData.street === "") return toast.error("Please enter street");
    else if (addAddressData.city === "")
      return toast.error("Please enter city");
    else if (addAddressData.state === "")
      return toast.error("Please enter state");
    else if (addAddressData.land_mark === "")
      return toast.error("Please enter landmark");
    else if (addAddressData.postal_code === "")
      return toast.error("Please enter pin code");
    else if (addAddressData.address_type === "")
      return toast.error("Please select address type");

    const formData = new FormData();
    formData.append("phone_number", addAddressData.phone_number);
    formData.append("door_flat", addAddressData.door_flat);
    formData.append("street", addAddressData.street);
    formData.append("city", addAddressData.city);
    formData.append("state", addAddressData.state);
    formData.append("land_mark", addAddressData.land_mark);
    formData.append("postal_code", addAddressData.postal_code);
    formData.append("address_type", addAddressData.address_type);

    API.address
      .CreateAddress(token, formData)
      .then((response) => {
        if (response) {
          setaddressData((prevAddress) => [...prevAddress, response]);
          toast.success("Address Updated");
        }
      })
      .finally(() => {
        setAddAddressData((prev) => ({
          ...prev,
          phone_number: "",
          door_flat: "",
          street: "",
          city: "",
          state: "",
          land_mark: "",
          postal_code: "",
          address_type: "",
        }));
        setAddAddressSlider(false);
      });
  };

  const deleteAddressSubmit = async () => {
    const address_id = deleteAddressData._id;
    API.address
      .DeleteAddress(token, { address_id })
      .then((response) => {
        if (response) {
          setaddressData((prevAddresses) =>
            prevAddresses.filter((address) => address._id !== address_id)
          );
          toast.success("Address Deleted");
        }
      })
      .finally(() => {
        setDeleteAddressModal(false);
        setDeleteAddressData("");
      });
  };

  useEffect(() => {
    getAllAddressOfUser();
  }, []);

  const getAllAddressOfUser = async () => {
    setLoading(true);
    API.address
      .GetAllAddress(token)
      .then((response) => {
        if (response) {
          setaddressData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const _this = {
    loading,
    setLoading,
    addressData,
    setaddressData,
    editAddressSlider,
    setEditAddressSlider,
    editAddressData,
    setEditAddressData,
    editAddressSubmit,
    deleteAddressModal,
    setDeleteAddressModal,
    deleteAddressData,
    setDeleteAddressData,
    deleteAddressSubmit,
    addAddressSlider,
    setAddAddressSlider,
    addAddressSubmit,
    addAddressData,
    setAddAddressData,
  };
  return (
    <>
      <Navbar />
      <div className="mt-[65px] mb-[60px] md:mb-[0px] md:mt-[90px]">
        <Body {..._this} />
      </div>
    </>
  );
};

export default ManageAddress;
