import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  cart_items: localStorage.getItem("cart_items")
    ? JSON.parse(localStorage.getItem("cart_items"))
    : [],
  total_amount: localStorage.getItem("total_amount")
    ? JSON.parse(localStorage.getItem("total_amount"))
    : 0,
  total_items: localStorage.getItem("total_items")
    ? JSON.parse(localStorage.getItem("total_items"))
    : 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartItems: (state, action) => {
      state.cart_items = action.payload;
      // console.log(state.cart_items);
      localStorage.setItem("cart_items", JSON.stringify(state.cart_items));
    },
    addToCart: (state, action) => {
      const product = action.payload;

      let isPresent = state.cart_items.find((p, i) => {
        if (p.product._id === product._id) {
          p.quantity++;
          return true;
        }
      });

      if (!isPresent) {
        const item = {
          product: product,
          quantity: 1,
        };
        state.cart_items.push(item);
      }
      state.total_items++;
      state.total_amount += product.product_price;

      // push on localStorage
      localStorage.setItem("cart_items", JSON.stringify(state.cart_items));
      localStorage.setItem("total_amount", JSON.stringify(state.total_amount));
      localStorage.setItem("total_items", JSON.stringify(state.total_items));
      toast.success("Item Added");
    },
    removeFromCart(state, action) {
      const index = state.cart_items.findIndex(
        (item) => item.product._id !== action.payload
      );
      state.total_items -= index.quantity;
      state.total_amount -=
        state.cart_items[index].product.product_price * index.quantity;
      state.cart_items.splice(index, 1);
      // push on localStorage
      localStorage.setItem("cart_items", JSON.stringify(state.cart_items));
      localStorage.setItem("total_amount", JSON.stringify(state.total_amount));
      localStorage.setItem("total_items", JSON.stringify(state.total_items));
    },
    increaseItemQuantity(state, action) {
      const product = action.payload;
      const item = state.cart_items.find(
        (item) => item.product._id === product._id
      );
      item.quantity++;
      state.total_items++;
      state.total_amount += item.product.product_price;

      localStorage.setItem("cart_items", JSON.stringify(state.cart_items));
      localStorage.setItem("total_amount", JSON.stringify(state.total_amount));
      localStorage.setItem("total_items", JSON.stringify(state.total_items));
    },
    decreaseItemQuantity(state, action) {
      const product = action.payload;
      const item = state.cart_items.find(
        (item) => item.product._id === product._id
      );
      item.quantity--;
      state.total_items--;
      state.total_amount -= item.product.product_price;
      if (item.quantity === 0) {
        const index = state.cart_items.indexOf(item);
        state.cart_items.splice(index, 1);
      }

      localStorage.setItem("cart_items", JSON.stringify(state.cart_items));
      localStorage.setItem("total_amount", JSON.stringify(state.total_amount));
      localStorage.setItem("total_items", JSON.stringify(state.total_items));
    },
    clearCart(state) {
      state.cart_items = [];
      state.total_items = 0;
      state.total_amount = 0;
      localStorage.removeItem("cart_items");
      localStorage.removeItem("total_amount");
      localStorage.removeItem("total_items");
    },
  },
});

export const {
  setCartItems,
  addToCart,
  removeFromCart,
  increaseItemQuantity,
  decreaseItemQuantity,
  setAvailability,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
