import React from "react";

const Body = () => {
  return (
    <div className="flex gap-y-2 min-h-[calc(100vh-100px)] w-[100%] justify-center overflow-x-hidden bg-black/10">
      <div className="h-full w-11/12 max-w-maxContent mx-auto bg-white mt-6 py-4 px-6">
        <div className="flex flex-col items-center gap-y-3 ">
          <div className="text-sm">
            <h2 className="text-lg font-bold mb-4">Shipping Policy</h2>
            <p>
              Thank you for choosing Caledon Kabab! We're committed to
              delivering your order promptly. Here's a brief overview of our
              delivery timeframe:
            </p>
            <ul className="list-disc pl-4 mt-2">
              <li>
                Delivery Timeframe: We aim to deliver your order within 1 to 3
                hours of order confirmation.
              </li>
            </ul>
            <p className="mt-4">
              Thank you for your understanding and trust in our service. Enjoy
              your meal!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
