import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { LoginForm } from "./LoginForm";
import { SignupForm } from "./SignupForm";
import { OtpForm } from "./OtpForm";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import API from "../../services/api";

import {
  setSidebar,
  setSignupData,
  setStep,
  setToken,
} from "../../reducer/slices/authSlice";
import { setUser } from "../../reducer/slices/profileSlice";

import { ValidateEmail, ValidateMobile } from "../../utils";

export const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { step, sidebar } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loginData, setloginData] = useState({
    user: "",
    password: "",
  });
  const [signupData, setSignUpData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  // login submit function
  const loginSubmit = (e) => {
    e.preventDefault();
    const toastID = toast.loading("Loading...");
    const email = ValidateEmail(loginData.user.trim()) ? loginData.user : "";
    const phone_number = ValidateMobile(loginData.user.trim())
      ? loginData.user
      : "";
    const password = loginData.password;
    API.auth
      .Login({ email, phone_number, password })
      .then((response) => {
        if (response) {
          dispatch(setToken(response.token));
          dispatch(setUser({ ...response.userData }));
          localStorage.setItem(
            "Caledon-Kabab-token",
            JSON.stringify(response.token)
          );
          localStorage.setItem(
            "Caledon-Kabab-user",
            JSON.stringify(response.userData)
          ); // important
          dispatch(setSidebar(false));
          if (response.userData.account_type === "Admin") {
            navigate("/dashboard/admin");
          }
          toast.success("Login Successful");
        }
      })
      .finally(() => {
        toast.dismiss(toastID);
      });
  };

  const PasswordStrength = (password) => {
    let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,24})(?=.*[0-9])(?=.*[@$!%*#?&])/;
    return re.test(password);
  };

  const { password, confirm_password } = signupData;
  // Handle Form Submission
  const signUpSubmit = (e) => {
    e.preventDefault();

    if (password !== confirm_password) {
      toast.error("Passwords Do Not Match");
      return;
    }

    if (!PasswordStrength(password)) {
      return toast.error("Password must be strong");
    }
    // Setting signup data to state
    // To be used after otp verification
    dispatch(setSignupData(signupData));
    // Send OTP to user for verification
    const data = {
      first_name: signupData.first_name,
      last_name: signupData.last_name,
      email: signupData.email,
    };
    const toastID = toast.loading("Loading...");
    API.auth
      .SendOtp(data)
      .then((response) => {
        if (response) {
          dispatch(setStep(3));
          toast.success("OTP has been set to your email account.");
        }
      })
      .finally(() => {
        toast.dismiss(toastID);
      });
  };

  const _this = {
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    loginData,
    setloginData,
    loginSubmit,
    signupData,
    setSignUpData,
    signUpSubmit,
  };
  return (
    <>
      <Drawer
        title={
          <div className="flex items-center justify-between">
            <AiOutlineClose
              onClick={() => dispatch(setSidebar(false))}
              size={22}
              className="text-gray-medium cursor-pointer"
            />
          </div>
        }
        placement="right"
        onClose={() => dispatch(setSidebar(false))}
        open={sidebar}
        width={window.innerWidth > 600 ? 600 : window.innerWidth}
        closable={false}
      >
        <div className="flex w-full justify-center">
          <div className="w-full flex flex-col gap-y-4 sm:w-9/12">
            {step === 1 && <LoginForm {..._this} />}
            {step === 2 && <SignupForm {..._this} />}
            {step === 3 && <OtpForm {..._this} />}
          </div>
        </div>
      </Drawer>
    </>
  );
};
