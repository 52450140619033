import React from "react";
import { Empty } from "antd";
import { FaIndianRupeeSign } from "react-icons/fa6";
import CartPopUp from "../../components/common/CartPopUp";
import { useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa";
import ProductModal from "./ProductModal";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Body = (_this) => {
  const { cart_items } = useSelector((state) => state.cart);
  return (
    <>
      <div className="w-full flex justify-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)]">
        <div className="flex flex-col w-11/12 max-w-maxContent gap-y-8">
          <div className="w-full border-b border-black/10 pt-10 pb-6">
            <h5 className="text-2xl font-bold text-black/80">
              {_this?.categoryData?.category_name}
            </h5>
            <p className="text-normal font-light text-black/70">
              {_this?.categoryData?.category_description}
            </p>
          </div>
          <div className="w-full h-full">
            {_this?.loading ? (
              <div className="h-full w-full flex items-center justify-center">
                Loading...
              </div>
            ) : (
              <div>
                {_this.productData.length === 0 ? (
                  <div className="pt-16">
                    <Empty description={<span>No Items</span>} />
                  </div>
                ) : (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-10">
                    {_this.productData.map((product) => {
                      return (
                        <div
                          key={product._id}
                          className="flex justify-center items-center w-full"
                        >
                          <div
                            key={product._id}
                            className="flex justify-center items-center w-full"
                          >
                            <div className="hidden sm:flex flex-col gap-y-2 ">
                              <img
                                alt="product_image"
                                src={`${BASE_URL + product.product_image}`}
                                className={`h-32 object-cover rounded-xl ${
                                  (!product.universal_availability ||
                                    !product?.is_available) &&
                                  "grayscale"
                                }`}
                              />
                              <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-1">
                                  <p className="text-xs xs:text-sm sm:text-base">
                                    {product.product_name}
                                  </p>
                                  <p
                                    onClick={() => {
                                      _this?.setProductDetailsModal(product);
                                    }}
                                    className="text-xs cursor-pointer text-blue-100"
                                  >
                                    Show Details
                                  </p>
                                </div>
                                <div className="flex justify-between">
                                  <p className="flex items-center font-semibold">
                                    <FaIndianRupeeSign />
                                    {product.product_price}
                                  </p>
                                  {product.universal_availability &&
                                  product?.is_available ? (
                                    <button
                                      className="border-2 border-primaryColor text-primaryColor font-bold py-1 px-2 flex items-center gap-2"
                                      onClick={() =>
                                        _this.addToCartHandler(product)
                                      }
                                    >
                                      <FaPlus />
                                      Add
                                    </button>
                                  ) : (
                                    <p>Not Available</p>
                                  )}
                                  {product.universal_availabilityy === true}
                                </div>
                              </div>
                            </div>
                            <div className="sm:hidden w-full h-full flex flex-col justify-between">
                              <div className="flex flex-row gap-x-3 w-full">
                                <div className="relative w-44">
                                  <img
                                    alt="product_image"
                                    src={`${BASE_URL + product.product_image}`}
                                    className={`h-20 xs:h-24 min-h-24 max-h-24 object-cover rounded-lg ${
                                      (!product.universal_availability ||
                                        !product?.is_available) &&
                                      "grayscale"
                                    }`}
                                  />
                                </div>
                                <div className="w-full flex flex-col justify-around pr-0.5 pb-1 xs:pr-0 xs:pb-0 xs:flex-row xs:justify-between">
                                  <div className="flex flex-col justify-around min-w-20 gap-1">
                                    <div className="flex flex-col gap-y-1 ">
                                      <p className="text-sm text-wrap">
                                        {product.product_name}
                                      </p>
                                      <p
                                        onClick={() => {
                                          _this?.setProductDetailsModal(
                                            product
                                          );
                                        }}
                                        className="text-xs cursor-pointer text-blue-100"
                                      >
                                        Show Details
                                      </p>
                                    </div>
                                    <p className="flex items-center text-xs xs:text-sm">
                                      <FaIndianRupeeSign />
                                      {product.product_price}
                                    </p>
                                  </div>
                                  <button
                                    onClick={() =>
                                      product.is_available &&
                                      _this.addToCartHandler(product)
                                    }
                                    className={`xs:h-full font-bold text-white ${
                                      !product.universal_availability ||
                                      !product?.is_available
                                        ? "bg-black/20"
                                        : "bg-primaryColor/90"
                                    }  xs:rounded-tr-md xs:rounded-br-md px-3`}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                              <div className="h-[1px] bg-black/10 mt-4"></div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {cart_items.length > 0 && (
        <div className="fixed bottom-[61px] md:bottom-0 w-full transition-all ease-in-out delay-500">
          <div className="max-w-maxContent mx-auto">
            <CartPopUp />
          </div>
        </div>
      )}
      {_this?.productDetailsModal && <ProductModal {..._this} />}
    </>
  );
};

export default Body;
