import React from "react";
import { Card, Avatar, List } from "antd";
import {
  Chart,
  registerables,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { FaIndianRupeeSign } from "react-icons/fa6";
import livePng from "../../assets/images/live.gif";

Chart.register(...registerables);
// Chart.register(ArcElement, Tooltip, Legend, Title);
Chart.defaults.plugins.legend.position = "left";

const Body = ({ _this }) => {
  const dataSetOfProducts = (productData, orderedData = []) => {
    var productCounts = {};
    productData.forEach(function (product) {
      productCounts[product.product_name] = 0;
    });
    orderedData.forEach(function (order) {
      order.order_items.forEach(function (orderItem) {
        var productName = orderItem.product.product_name;
        if (productCounts.hasOwnProperty(productName)) {
          productCounts[productName]++;
        }
      });
    });
    var mappedData = productData.map(function (product) {
      var productName = product.product_name;
      return productCounts.hasOwnProperty(productName)
        ? productCounts[productName]
        : 0;
    });
    return mappedData;
  };

  const generateRandomColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const color = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)})`;
      colors.push(color);
    }
    return colors;
  };

  const d = dataSetOfProducts(
    _this?.productData,
    _this.orderDataByDate?.totalOrders
  );
  const chartOrders = {
    labels: _this?.productData.map((product) => product?.product_name),
    datasets: [
      {
        data: d,
        backgroundColor: generateRandomColors(d.length),
        hoverOffset: 4,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
  };
  const labels = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dataforOrderCount = {
    labels: labels,
    datasets: [
      {
        label: "Orders",
        data: _this?.orderGraphData?.orderCountsinMonth,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataforOrderAmount = {
    labels: labels,
    datasets: [
      {
        label: "Amount",
        data: _this?.orderGraphData?.orderAmountinMonth,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const config = {
    type: "bar",
    data: dataforOrderCount,
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <div>
      <Card
        title={
          <div className="mt-2 flex items-center justify-between py-2">
            <p className="text-sm sm:text-base lg:text-xl font-medium leading-loose">
              Analytics
            </p>
            <div>
              <div date-rangepicker className="flex items-center gap-x-4">
                <div className="flex items-center">
                  <div className="relative">
                    <input
                      name="start"
                      value={_this?.startDate}
                      type="date"
                      className="border border-black/20 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                      placeholder="Select date start"
                      onChange={(e) => {
                        _this?.setStartDate(e.target.value);
                      }}
                    />
                  </div>
                  <span className="mx-4 text-gray-500">to</span>
                  <div className="relative">
                    <input
                      name="end"
                      value={_this?.endDate}
                      type="date"
                      className="border border-black/20 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                      placeholder="Select date end"
                      onChange={(e) => {
                        _this?.setEndDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <button
                  onClick={() => _this?.handleLoadData()}
                  className="text-white text-sm sm:text-base py-1.5 px-2.5 sm:py-2 sm:px-4 bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200  shadow-xl shadow-shadowColor border-0 focus:outline-none hover:bg-primary-dark rounded-lg font-medium inline-flex items-center"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        }
        className="bg-bg-gray text-gray-medium tracking-wider flex flex-col justify-start w-full"
      >
        <div className="min-h-[calc(100vh-200px)] flex flex-col gap-y-20">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-x-8 gap-y-4">
            <div className="flex flex-col gap-y-4 px-6 pt-2 pb-5 bg-black/5">
              <p className="font-light">Total Orders</p>
              <p className="text-3xl font-bold">
                {_this.orderDataByDate?.totalOrdersCount}
              </p>
            </div>
            <div className="flex flex-col gap-y-4 px-6 pt-2 pb-5 bg-black/5">
              <p className="font-light flex gap-x-1 justify-between items-center">
                <span>Live Orders</span>
                <img src={livePng} className="w-5 h-full" />
              </p>
              <p className="text-3xl font-bold">
                {_this.orderDataByDate?.totalLiveOrders}
              </p>
            </div>
            <div className="flex flex-col gap-y-4 px-6 pt-2 pb-5 bg-black/5">
              <p className="font-light">Completed Orders</p>
              <p className="text-3xl font-bold">
                {_this.orderDataByDate?.totalCompletedOrders}
              </p>
            </div>
            <div className="flex flex-col gap-y-4 px-6 pt-2 pb-5 bg-black/5">
              <p className="font-light">Cancelled Orders</p>
              <p className="text-3xl font-bold">
                {_this.orderDataByDate?.totalCancelledOrders}
              </p>
            </div>
            <div className="flex flex-col gap-y-4 px-6 pt-2 pb-5 bg-black/5">
              <p className="font-light">Total Amount</p>
              <p className="text-3xl font-bold flex items-center gap-x-1">
                <span>
                  <FaIndianRupeeSign />{" "}
                </span>
                {_this.orderDataByDate?.totalCompletedAmount}
              </p>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-4 overflow-hidden h-full">
            <div className="h-full">
              <Line data={dataforOrderCount} options={options} />
            </div>
            <div className="h-full">
              <Bar data={dataforOrderAmount} options={options} />
            </div>
          </div>
          <div className="w-full h-full hidden md:flex">
            <div className=" w-full px-32">
              <Doughnut data={chartOrders} options={options} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Body;
