import React from "react";
import Navbar from "../../components/Navbar";
import Body from "./Body";
import Footer from "../../components/Footer";

const ShippingPolicy = () => {
  return (
    <main>
      <Navbar />
      <div className={`mt-[65px] md:mt-[90px]`}>
        <Body />
      </div>
      <Footer />
    </main>
  );
};

export default ShippingPolicy;
