import React from "react";
import PhoneNumberVerifyModal from "./PhoneNumberVerifyModal";
import EmailVerifyModal from "./EmailVerificationModal";

const Body = (_this) => {
  return (
    <div className="w-full flex justify-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)]">
      <div className="flex flex-col w-full sm:w-10/12 md:w-8/12 lg:w-6/12 max-w-maxContent gap-y-4 py-3">
        <div className=" flex flex-col gap-y-2">
          <span className="text-xs text-black/70 pl-3 uppercase">
            Edit Profile
          </span>
          <div className="h-[1px] bg-black/10 my-1"></div>
          <div className="w-full gap-y-3 flex flex-col justify-center items-center">
            <div className="w-11/12  flex justify-between">
              <label className="relative w-full">
                <input
                  required
                  type="text"
                  name="phone_number"
                  value={_this.editPhoneNumberData.phone_number}
                  onChange={(e) => {
                    _this.setEditPhoneNumberData((prev) => ({
                      ...prev,
                      phone_number: e.target.value,
                    }));
                  }}
                  placeholder="Phone Number"
                  className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                />
                <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                  Phone Number <sup className="text-pink-200">*</sup>
                </span>
              </label>
              <button
                onClick={() => _this.updatePhoneNumberSubmit()}
                className="bg-primaryColor px-2 h-full border border-primaryColor text-white font-bold"
              >
                Verify
              </button>
            </div>
            <div className="w-11/12 flex justify-between">
              <label className="relative w-full">
                <input
                  required
                  type="text"
                  name="email"
                  value={_this.editEmailData.email}
                  onChange={(e) => {
                    _this.setEditEmailData((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                  placeholder="Email"
                  className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                />
                <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                  Email <sup className="text-pink-200">*</sup>
                </span>
              </label>
              <button
                onClick={() => _this.updateEmailSubmit()}
                className="bg-primaryColor px-2 h-full border border-primaryColor text-white font-bold"
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
      {_this.phoneNumberVerifyModal && <PhoneNumberVerifyModal {..._this} />}
      {_this.emailVerifyModal && <EmailVerifyModal {..._this} />}
    </div>
  );
};

export default Body;
