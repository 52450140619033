import React from "react";
import { Card, Empty, Switch } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { AiOutlineEdit, AiOutlineUser } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi";
import { ProductTable } from "./ProductTable";
import AddProductModal from "./AddProductModal";
import EditProductModal from "./EditProductModal";
import DeleteModal from "./DeleteModal";

const Body = (_this) => {
  return (
    <>
      <Card
        title={
          <div className="mt-2 flex items-center justify-between py-2">
            <p className="text-sm sm:text-base lg:text-xl font-medium leading-loose">
              Products
            </p>
            <div className="flex items-center gap-x-3">
              {_this.productData.length !== 0 && (
                <Switch
                  value={_this.productData[0]?.universal_availability}
                  style={{
                    backgroundColor: _this.productData[0]
                      ?.universal_availability
                      ? "#32CD32"
                      : "gray",
                  }}
                  onClick={(checked) =>
                    _this.ChangeUniversalAvailability(checked)
                  }
                />
              )}
              <button
                onClick={() => _this.addProductModalToggle()}
                className="text-white text-sm sm:text-base py-1.5 px-2.5 sm:py-2 sm:px-4 bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200  shadow-xl shadow-shadowColor border-0 focus:outline-none hover:bg-primary-dark rounded-lg font-medium inline-flex items-center"
              >
                <PlusOutlined className="mr-2" />
                Add Product
              </button>
            </div>
          </div>
        }
        className="bg-bg-gray text-gray-medium tracking-wider flex flex-col justify-start w-full"
      >
        {_this.productData.length === 0 ? (
          <div className="flex flex-col items-center justify-center gap-y-2  min-h-[calc(100vh-200px)]">
            {/* <div className="flex justify-center items-center text-xs sm:text-sm">
              No Product added yet
            </div> */}
            <Empty description={<span>No Product added yet</span>} />
          </div>
        ) : (
          <div className="flex min-h-[calc(100vh-190px)] max-h-[calc(100vh-190px)] w-[100%] justify-center">
            <ProductTable {..._this} />
          </div>
        )}
      </Card>
      <AddProductModal {..._this} />
      <EditProductModal {..._this} />
      <DeleteModal {..._this} />
    </>
  );
};

export default Body;
