import React from "react";

const PrivateLtd = (_this) => {
  return (
    <div className="w-full flex flex-col items-center">
      <h2 className="text-lg text-black underline">For Private Ltd</h2>
      <div className="w-full flex justify-center">
        <div className="w-8/12">
          <div className="flex flex-col gap-y-2">
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Email
                <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="text"
                name="email"
                value={_this?.pvtlmtdFormData?.email}
                onChange={(e) => {
                  _this?.setPvtLmtdFormData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
                placeholder="Email"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm p-2.5"
              />
            </label>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Phone number
                <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="text"
                name="phone_number"
                value={_this?.pvtlmtdFormData?.phone_number}
                onChange={(e) => {
                  _this?.setPvtLmtdFormData((prev) => ({
                    ...prev,
                    phone_number: e.target.value,
                  }));
                }}
                placeholder="Phone Number"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm p-2.5"
              />
            </label>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                GST/Trade licence/Shop Act/IEC{" "}
                <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="file"
                name="gst"
                // value={_this?.pvtlmtdFormData?.gst}
                onChange={(e) => {
                  _this?.setPvtLmtdFormData((prev) => ({
                    ...prev,
                    gst: e.target.files[0],
                  }));
                }}
                placeholder="GST/Trade licence/Shop Act/IEC"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm p-2.5"
              />
            </label>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Current Account (Cancelled cheque/Bank statement)
                <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="file"
                name="current_account"
                // value={_this?.pvtlmtdFormData?.current_account}
                onChange={(e) => {
                  _this?.setPvtLmtdFormData((prev) => ({
                    ...prev,
                    current_account: e.target.files[0],
                  }));
                }}
                placeholder="Current Account (cancelled cheque/bank statement)"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm p-2.5"
              />
            </label>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Director pan card
                <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="file"
                name="director_pan_card"
                // value={_this?.pvtlmtdFormData?.director_pan_card}
                onChange={(e) => {
                  _this?.setPvtLmtdFormData((prev) => ({
                    ...prev,
                    director_pan_card: e.target.files[0],
                  }));
                }}
                placeholder="Any one partners pan card"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm p-2.5"
              />
            </label>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Certificate of incorporation
                <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="file"
                name="certificate_corporation"
                // value={_this?.pvtlmtdFormData?.certificate_corporation}
                onChange={(e) => {
                  _this?.setPvtLmtdFormData((prev) => ({
                    ...prev,
                    certificate_corporation: e.target.files[0],
                  }));
                }}
                placeholder="Certificate of incorporation"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm p-2.5"
              />
            </label>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Company Pan Card
                <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="file"
                name="company_pan_card"
                // value={_this?.pvtlmtdFormData?.company_pan_card}
                onChange={(e) => {
                  _this?.setPvtLmtdFormData((prev) => ({
                    ...prev,
                    company_pan_card: e.target.files[0],
                  }));
                }}
                placeholder="Company Pan Card"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm p-2.5"
              />
            </label>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                FSSAI Licence
                <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="file"
                name="fssai_licence"
                // value={_this?.pvtlmtdFormData?.fssai_licence}
                onChange={(e) => {
                  _this?.setPvtLmtdFormData((prev) => ({
                    ...prev,
                    fssai_licence: e.target.files[0],
                  }));
                }}
                placeholder="FSSAI Licence"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm p-2.5"
              />
            </label>
            <div className="w-full flex justify-end pt-4">
              <button
                size="large"
                onClick={() => _this?.handleSubmit()}
                type="submit"
                className="text-white text-sm sm:text-base py-1.5 px-2.5 sm:py-2 sm:px-4 bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200  shadow-xl shadow-shadowColor border-0 focus:outline-none hover:bg-primary-dark  font-medium inline-flex items-center"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateLtd;
