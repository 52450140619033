import React from "react";

const SaltKey = (_this) => {
  return (
    <div className="w-full flex flex-col items-center">
      <h2 className="text-lg text-black underline">Enter the Details</h2>
      <div className="w-full flex justify-center">
        <div className="w-8/12">
          <div className="flex flex-col gap-y-2">
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                M-ID
                <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="text"
                name="m_id"
                value={_this?.saltKeyData?.m_id}
                onChange={(e) => {
                  _this?.setSaltkeyData((prev) => ({
                    ...prev,
                    m_id: e.target.value,
                  }));
                }}
                placeholder="GST/Trade licence/Shop Act/IEC"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm p-2.5"
              />
            </label>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Salt Key
                <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="text"
                name="salt_key"
                value={_this?.saltKeyData?.salt_key}
                onChange={(e) => {
                  _this?.setSaltkeyData((prev) => ({
                    ...prev,
                    salt_key: e.target.value,
                  }));
                }}
                placeholder="Current Account (cancelled cheque/bank statement)"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm p-2.5"
              />
            </label>
            <div className="w-full flex justify-end pt-4">
              <button
                size="large"
                onClick={() => _this?.handleSaltKeySubmit()}
                type="button"
                className="text-white text-sm sm:text-base py-1.5 px-2.5 sm:py-2 sm:px-4 bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200  shadow-xl shadow-shadowColor border-0 focus:outline-none hover:bg-primary-dark  font-medium inline-flex items-center"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaltKey;
