import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Body from "./Body";
import { getAllOrderUser } from "../../services/operations/ordersApi";
import { useSelector } from "react-redux";

const CustomerOrders = () => {
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [ordersData, setOrderData] = useState([]);

  useEffect(() => {
    getAllUserOrder();
  }, []);

  const getAllUserOrder = async () => {
    setLoading(true);
    try {
      if (token) {
        const res = await getAllOrderUser(token);
        setOrderData(res);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const _this = {
    loading,
    setLoading,
    ordersData,
    setOrderData,
  };
  return (
    <>
      <Navbar />
      <div className="mt-[65px] mb-[60px] md:mb-[0px] md:mt-[90px]">
        <Body {..._this} />
      </div>
    </>
  );
};

export default CustomerOrders;
