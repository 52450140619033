import React, { useState, useEffect } from "react";
import moment from "moment";
import { Card } from "antd";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import toast from "react-hot-toast";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SingleOrder = ({ order, _this }) => {
  const [statusTab, setStatusTab] = useState(1);
  const orderStatus = [
    "Ordered",
    "Preparing",
    "Ready",
    "Delivery",
    "Reached",
    "Completed",
    "Cancelled",
  ];
  const changeStatus = async (value) => {
    const data = {
      order_id: order._id,
      order_status: value,
    };
    _this.ChangeOrderStatus(data);
  };

  useEffect(() => {
    const index = orderStatus.indexOf(order.order_status);
    setStatusTab(index + 1);
  }, [changeStatus]);

  const styleOfArrow = (conditionCheck) => {
    return {
      content: "",
      position: "absolute",
      right: "-23px",
      width: "0",
      height: "0",
      borderTop: "80px solid transparent",
      borderLeft: conditionCheck ? "30px solid #5BF08E" : "30px solid #D0D5DD",
      borderBottom: "80px solid transparent",
    };
  };
  const styleOfBackArrow = {
    content: "",
    position: "absolute",
    width: "0",
    height: "0",
    left: "0",
    borderTop: "80px solid transparent",
    borderLeft: "30px solid white",
    borderBottom: "80px solid transparent",
  };

  return (
    <Card
      className={`min-h-36 w-full shadow-md bg-grayBackground/30`}
      bordered={true}
      bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      headStyle={{
        padding: 0,
        justifyContent: "flex-start",
      }}
      title={
        <div>
          {order.order_status !== "Completed" &&
            order.order_status !== "Cancelled" && (
              <div>
                <div className="hidden md:flex items-center text-white w-full h-14">
                  <div className="relative flex items-center h-full w-full">
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        changeStatus("Ordered");
                      }}
                      className={`relative flex items-center justify-center cursor-pointer p-1 z-50 w-[16%] h-full ${
                        statusTab >= 1 ? "bg-bgCompleted" : "bg-bgNotCompleted"
                      }`}
                    >
                      <p className={`${statusTab === 1 && "animate-pulse"}`}>
                        Ordered
                      </p>
                      <div style={styleOfArrow(statusTab >= 1)}></div>
                    </div>

                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        changeStatus("Preparing");
                      }}
                      className={`relative flex items-center justify-center cursor-pointer p-1 z-40 w-[16%] h-full ${
                        statusTab >= 2 ? "bg-bgCompleted" : "bg-bgNotCompleted"
                      }`}
                    >
                      <div style={styleOfBackArrow}></div>
                      <span className={`${statusTab === 2 && "animate-pulse"}`}>
                        Preparing
                      </span>
                      <div style={styleOfArrow(statusTab >= 2)}></div>
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        changeStatus("Ready");
                      }}
                      className={`relative flex items-center justify-center cursor-pointer p-1 z-30 w-[16%] h-full ${
                        statusTab >= 3 ? "bg-bgCompleted" : "bg-bgNotCompleted"
                      }`}
                    >
                      <div style={styleOfBackArrow}></div>
                      <span className={`${statusTab === 3 && "animate-pulse"}`}>
                        Ready
                      </span>
                      <div style={styleOfArrow(statusTab >= 3)}></div>
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        changeStatus("Delivery");
                      }}
                      className={`relative flex items-center justify-center cursor-pointer p-1 z-20 w-[16%] h-full ${
                        statusTab >= 4 ? "bg-bgCompleted" : "bg-bgNotCompleted"
                      }`}
                    >
                      <div style={styleOfBackArrow}></div>
                      <span className={`${statusTab === 4 && "animate-pulse"}`}>
                        Delivery
                      </span>
                      <div style={styleOfArrow(statusTab >= 4)}></div>
                    </div>

                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        changeStatus("Reached");
                      }}
                      className={`relative flex items-center justify-center cursor-pointer p-1 z-10 w-[16%] h-full ${
                        statusTab >= 5 ? "bg-bgCompleted" : "bg-bgNotCompleted"
                      }`}
                    >
                      <div style={styleOfBackArrow}></div>
                      <span className={`${statusTab === 5 && "animate-pulse"}`}>
                        Reached
                      </span>
                      <div style={styleOfArrow(statusTab >= 5)}></div>
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        changeStatus("Completed");
                      }}
                      className={`relative flex items-center justify-center cursor-pointer p-1 z-0 w-[20%] h-full ${
                        statusTab >= 6 ? "bg-bgCompleted" : "bg-bgNotCompleted"
                      }`}
                    >
                      <div style={styleOfBackArrow}></div>
                      <span className={`${statusTab === 6 && "animate-pulse"}`}>
                        Completed
                      </span>
                    </div>
                  </div>
                </div>
                <div className="md:hidden flex justify-around items-center h-14">
                  <div>
                    <button
                      onClick={() => {
                        const index = orderStatus.indexOf(order.order_status);
                        if (index === 0) return toast.error("No previous step");
                        const value = orderStatus[index - 1];
                        changeStatus(value);
                      }}
                      className={`flex items-center gap-x-2 px-3 py-1 bg-primaryColor text-white rounded-sm ${
                        order.order_status === "Ordered" &&
                        "bg-grayBackground/20"
                      }`}
                    >
                      <FaArrowLeft /> Prev
                    </button>
                  </div>
                  <p>{order.order_status}</p>
                  <div>
                    <button
                      onClick={() => {
                        const index = orderStatus.indexOf(order.order_status);
                        const value = orderStatus[index + 1];
                        changeStatus(value);
                      }}
                      className="flex items-center gap-x-2 px-3 py-1 bg-primaryColor text-white rounded-sm"
                    >
                      Next
                      <FaArrowRight />
                    </button>
                  </div>
                </div>
              </div>
            )}
          {(order.order_status === "Completed" ||
            order.order_status === "Cancelled") && (
            <div className="text-black w-full flex justify-center items-center h-14">
              {order.order_status}
            </div>
          )}
        </div>
      }
    >
      <div className="flex flex-row w-full" style={{ flex: 1 }}>
        <div className="flex-1 flex-col justify-center items-center w-full">
          <div className="flex flex-col gap-1">
            <div className="text-gray-medium text-sm font-normal leading-tight my-2">
              <div className="flex flex-wrap gap-x-3 px-6">
                {order.order_items.slice(0, 3).map((items) => {
                  return (
                    <div
                      key={items._id}
                      className="flex flex-col items-center gap-y-1"
                    >
                      <img
                        alt="product_image"
                        src={`${BASE_URL + items.product.product_image}`}
                        className={`h-16 md:h-24 object-cover rounded-md ${
                          order.order_status === "Cancelled" && "grayscale"
                        }`}
                      />
                      <p className="text-xs text-light">
                        {items.product.product_name}
                        {" × "}
                        {items.quantity}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full h-[1px] bg-black/10 mx-5"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 px-4 py-4 w-full">
              <div className="p-4 border border-black/5 rounded-lg w-full max-w-90">
                <div className="flex flex-col justify-between text-wrap w-full">
                  <p className="text-xs">
                    Phone Number : {order.order_address.phone_number}
                  </p>
                  <p className="text-xs">
                    Door/Flat : {order.order_address.door_flat}
                  </p>
                  <p className="text-xs">
                    Street : {order.order_address.street}
                  </p>
                  <p className="text-xs">City : {order.order_address.city}</p>
                  <p className="text-xs">
                    Landmark : {order.order_address.land_mark}
                  </p>
                  <p className="text-xs">State : {order.order_address.state}</p>
                  <p className="text-xs">
                    Pin : {order.order_address.postal_code}
                  </p>
                </div>
              </div>
              <div className="p-4 border border-black/5 rounded-lg">
                <div className="flex flex-col justify-between">
                  <p className="text-xs">
                    {moment(order.order_date).format("Do MMMM YYYY, h:mm a")}
                  </p>
                  <p className="text-xs text-wrap">
                    Total Amount : {order.total_amount}
                  </p>
                  <p className="text-xs text-wrap">
                    Payment Mod : {order.payment_mod}
                  </p>
                  <p className="text-xs text-wrap">
                    {order.payment_mod === "Online" && (
                      <span>Payment Id : {order.payment_id}</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default SingleOrder;
