import React from "react";
import { Modal } from "antd";
import { MdOutlineFileUpload } from "react-icons/md";
import Upload from "./Upload";

const AddProductModal = (_this) => {
  return (
    <>
      <Modal
        title="Add Product"
        open={_this.addProductModal}
        onOk={_this.AddProductSubmit}
        onCancel={_this.addProductModalToggle}
        width={500}
        footer={
          <div className="flex items-center justify-end gap-x-2">
            <button
              className="cursor-pointer text-sm sm:text-base py-1.5 px-2.5 sm:py-2 sm:px-4 rounded-md bg-white hover:bg-shadowColor hover:text-primaryColor border border-shadowColor font-semibold"
              size="large"
              onClick={() => _this.addProductModalToggle()}
            >
              Cancel
            </button>
            <button
              size="large"
              onClick={() => _this.AddProductSubmit()}
              type="primary"
              className="text-white text-sm sm:text-base py-1.5 px-2.5 sm:py-2 sm:px-4 bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200  shadow-xl shadow-shadowColor border-0 focus:outline-none hover:bg-primary-dark rounded-lg font-medium inline-flex items-center"
            >
              Submit
            </button>
          </div>
        }
      >
        <div className="flex justify-center my-">
          <form className="flex w-full md:w-11/12 flex-col items-center gap-y-4">
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Product Name <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="text"
                name="product_name"
                value={_this.addProductModalData.product_name}
                onChange={(e) => {
                  _this.setAddProductModalData((prev) => ({
                    ...prev,
                    product_name: e.target.value,
                  }));
                }}
                placeholder="Enter Product Name"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
              />
            </label>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Product Description <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="text"
                name="product_description"
                value={_this.addProductModalData.product_description}
                onChange={(e) => {
                  _this.setAddProductModalData((prev) => ({
                    ...prev,
                    product_description: e.target.value,
                  }));
                }}
                placeholder="Enter Description"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
              />
            </label>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Product Price <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="number"
                name="product_price"
                value={_this.addProductModalData.product_price}
                onChange={(e) => {
                  _this.setAddProductModalData((prev) => ({
                    ...prev,
                    product_price: e.target.value,
                  }));
                }}
                placeholder="Enter Price"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
              />
            </label>
            <div className="flex flex-col space-y-2 w-full">
              <label className="text-sm text-subtext" htmlFor="courseCategory">
                Product Category <sup className="text-pink-200">*</sup>
              </label>
              <select
                id="productCategory"
                value={_this?.addProductModalData?.product_category}
                className=" shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
                onChange={(e) => {
                  _this.setAddProductModalData((prev) => ({
                    ...prev,
                    product_category: e.target.value,
                  }));
                }}
              >
                <option value="" disabled selected>
                  Choose a Category
                </option>
                {!_this.loading &&
                  _this.categoryData?.map((category, indx) => (
                    <option key={indx} value={category?._id}>
                      {category?.category_name}
                    </option>
                  ))}
              </select>
            </div>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Product Quantiy <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="number"
                name="product_quantity"
                value={_this.addProductModalData.product_quantity}
                onChange={(e) => {
                  _this.setAddProductModalData((prev) => ({
                    ...prev,
                    product_quantity: e.target.value,
                  }));
                }}
                placeholder="Enter Quantity"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
              />
            </label>
            <div className="mb-11 w-full ">
              <Upload
                value={_this?.addProductImage}
                setValue={_this?.setAddProductImage}
              />
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddProductModal;
