import React from "react";
import { useSelector } from "react-redux";
import { Empty, Skeleton } from "antd";
import { MdAdd } from "react-icons/md";

const Address = (_this) => {
  const { token } = useSelector((state) => state.auth);
  return (
    <div className="w-11/12 mx-auto overflow-x-hidden bg-white">
      <div className="flex flex-col gap-3 p-4">
        <div className="flex items-center justify-between">
          <h4 className="font-semibold text-black/70">Address</h4>
          <div>
            {token && (
              <button
                onClick={() => _this.setAddAddressSlider(true)}
                className=" text-greenColor border border-greenColor p-1 px-3 font-semibold flex items-center gap-1"
              >
                <MdAdd />
                Add New
              </button>
            )}
          </div>
        </div>
        <div className="h-[1px] bg-black/5"></div>
        <div>
          {_this.loading ? (
            <div className="flex flex-col gap-y-3">
              <Skeleton active />
            </div>
          ) : (
            <div>
              {token !== null ? (
                <div className="flex flex-col gap-y-5">
                  <div>
                    {_this.addressData.length === 0 ? (
                      <div>
                        <Empty description="No Address Found" />
                      </div>
                    ) : (
                      <ul className="grid grid-cols-1  md:grid-cols-2 gap-3">
                        {_this.addressData.map((address, index) => {
                          return (
                            <li key={address._id}>
                              <input
                                type="radio"
                                id={address._id}
                                name="order_address"
                                value={address}
                                className="hidden peer"
                                onChange={() => {
                                  _this.setOrderAddress((prev) => ({
                                    ...prev,
                                    address_id: address._id,
                                    phone_number: address.phone_number,
                                    door_flat: address.door_flat,
                                    street: address.street,
                                    city: address.city,
                                    state: address.state,
                                    land_mark: address.land_mark,
                                    postal_code: address.postal_code,
                                  }));
                                }}
                                required
                              />
                              <label
                                htmlFor={address._id}
                                className="inline-flex items-center justify-between w-full p-3 bg-white border border-black/10 rounded-lg cursor-pointer peer-checked:bg-greenColor/10"
                              >
                                <div className="px-3 pt-2 select-none">
                                  <div className="flex justify-between">
                                    <h4 className="text-xs">
                                      Address {index + 1}
                                    </h4>
                                    <p className="text-xs">
                                      {address.address_type}
                                    </p>
                                  </div>
                                  <div className="h-[1px] bg-black/10 my-1"></div>
                                  <div className="text-xs font-extralight">
                                    <p>
                                      <span className="font-normal">
                                        Phone Number :
                                      </span>{" "}
                                      {address.phone_number}
                                    </p>
                                    <p>
                                      <span className="font-normal">
                                        Door / Flat :
                                      </span>{" "}
                                      {address.door_flat}
                                    </p>
                                    <p>
                                      <span className="font-normal">
                                        Street :
                                      </span>{" "}
                                      {address.street}
                                    </p>
                                    <p>
                                      <span className="font-normal">
                                        City :
                                      </span>{" "}
                                      {address.city}
                                    </p>
                                    <p>
                                      <span className="font-normal">
                                        State :
                                      </span>{" "}
                                      {address.state}
                                    </p>

                                    <p>
                                      <span className="font-normal">
                                        Landmark :
                                      </span>{" "}
                                      {address.land_mark}
                                    </p>
                                    <p>
                                      <span className="font-normal">Pin :</span>{" "}
                                      {address.postal_code}
                                    </p>
                                  </div>
                                  <div className="flex justify-between">
                                    <div></div>
                                    <button
                                      className="text-md font-semibold text-primaryColor"
                                      onClick={() => {
                                        _this.setEditAddressSlider(true);
                                        _this.setEditAddressData((prev) => ({
                                          ...prev,
                                          address_id: address._id,
                                          phone_number: address.phone_number,
                                          door_flat: address.door_flat,
                                          street: address.street,
                                          city: address.city,
                                          state: address.state,
                                          land_mark: address.land_mark,
                                          postal_code: address.postal_code,
                                          address_type: address.address_type,
                                        }));
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              ) : (
                // <div className="w-full mx-auto p-5">
                //   <form className="flex w-full flex-col gap-y-6">
                //     <label className="relative">
                //       <input
                //         required
                //         type="text"
                //         name="user_name"
                //         value={_this.userData.user_name}
                //         onChange={(e) => {
                //           _this.setUserData((prev) => ({
                //             ...prev,
                //             user_name: e.target.value,
                //           }));
                //         }}
                //         placeholder="Name"
                //         className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                //       />
                //       <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                //         Name <sup className="text-pink-200">*</sup>
                //       </span>
                //     </label>
                //     <label className="relative">
                //       <input
                //         required
                //         type="text"
                //         name="phone_number"
                //         value={_this.userData.phone_number}
                //         onChange={(e) => {
                //           _this.setUserData((prev) => ({
                //             ...prev,
                //             phone_number: e.target.value,
                //           }));
                //         }}
                //         placeholder="Phone Number"
                //         className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                //       />
                //       <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                //         Phone Number <sup className="text-pink-200">*</sup>
                //       </span>
                //     </label>
                //     <label className="relative">
                //       <input
                //         required
                //         type="text"
                //         name="street"
                //         value={_this.orderAddress.street}
                //         onChange={(e) => {
                //           _this.setOrderAddress((prev) => ({
                //             ...prev,
                //             street: e.target.value,
                //           }));
                //         }}
                //         placeholder="Street"
                //         className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                //       />
                //       <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                //         Street <sup className="text-pink-200">*</sup>
                //       </span>
                //     </label>
                //     <label className="relative">
                //       <input
                //         required
                //         type="text"
                //         name="city"
                //         value={_this.orderAddress.city}
                //         onChange={(e) => {
                //           _this.setOrderAddress((prev) => ({
                //             ...prev,
                //             city: e.target.value,
                //           }));
                //         }}
                //         placeholder="Enter You City"
                //         className="w-full outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                //       />
                //       <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                //         City <sup className="text-pink-200">*</sup>
                //       </span>
                //     </label>
                //     <label className="relative">
                //       <input
                //         required
                //         type="text"
                //         name="state"
                //         value={_this.orderAddress.state}
                //         onChange={(e) => {
                //           _this.setOrderAddress((prev) => ({
                //             ...prev,
                //             state: e.target.value,
                //           }));
                //         }}
                //         placeholder="Enter You State"
                //         className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                //       />
                //       <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                //         State <sup className="text-pink-200">*</sup>
                //       </span>
                //     </label>
                //     <label className="relative">
                //       <input
                //         required
                //         type="text"
                //         name="land_mark"
                //         value={_this.orderAddress.land_mark}
                //         onChange={(e) => {
                //           _this.setOrderAddress((prev) => ({
                //             ...prev,
                //             land_mark: e.target.value,
                //           }));
                //         }}
                //         placeholder="Enter You Landmark"
                //         className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                //       />
                //       <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                //         Landmark <sup className="text-pink-200">*</sup>
                //       </span>
                //     </label>
                //     <label className="relative">
                //       <input
                //         required
                //         type="text"
                //         name="postal_code"
                //         maxlength="6"
                //         value={_this.orderAddress.postal_code}
                //         onChange={(e) => {
                //           _this.setOrderAddress((prev) => ({
                //             ...prev,
                //             postal_code: e.target.value,
                //           }));
                //         }}
                //         placeholder="Enter You Pin code"
                //         className="w-full outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                //       />
                //       <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50  absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                //         Pin <sup className="text-pink-200">*</sup>
                //       </span>
                //     </label>
                //   </form>
                // </div>
                <div className="flex justify-center font-light">
                  Login to show your address
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Address;
