import { orderEndpoints } from "../apis";
import { apiConnector, handleResponse } from "./core";
const { UPDATE_ORDER_STATUS_API } = orderEndpoints;

const order = {
  ChangeOrderStatus: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", UPDATE_ORDER_STATUS_API, data, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
};

export default order;
