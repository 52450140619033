import { apiConnector, handleResponse } from "./core";
import { authEndpoints } from "../apis";

const {
  SEND_OTP_API,
  SIGNUP_API,
  LOGIN_API,
  FORGOT_PASSWORD_API,
  RESEND_OTP_API,
} = authEndpoints;

const auth = {
  Login: async (data) => {
    let response = null;
    try {
      response = await apiConnector("POST", LOGIN_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  SendOtp: async (data) => {
    let response = null;
    try {
      response = await apiConnector("POST", SEND_OTP_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  SignUp: async (data) => {
    let response = null;
    try {
      response = await apiConnector("POST", SIGNUP_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  ForgotPassword: async (data) => {
    let response = null;
    try {
      response = await apiConnector("POST", FORGOT_PASSWORD_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  ResendOtp: async (data) => {
    let response = null;
    try {
      response = await apiConnector("POST", RESEND_OTP_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
};

export default auth;
