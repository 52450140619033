import React from "react";
import { IoCall, IoMail } from "react-icons/io5";
const Body = () => {
  return (
    <div className="w-full flex justify-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)] bg-black/5">
      <div className="flex flex-col w-full h-full sm:w-10/12 md:w-8/12 lg:w-6/12 max-w-maxContent gap-y-4 py-3">
        <div className="w-full h-full flex flex-col gap-y-3">
          <span className="text-xs text-black/70 pl-3 uppercase">Help</span>
          <div className="h-[1px] bg-black/10 my-1"></div>
          <div className="flex justify-center">
            <div className="flex flex-col gap-y-4 w-11/12">
              <div className="flex w-full justify-between items-center">
                <p className="flex flex-col text-sm font-bold sm:text-xl">
                  +917797222128{" "}
                  <span className="text-xs font-light">
                    for food related query & problem
                  </span>
                </p>
                <a
                  href="tel:+9177972 22128"
                  className="flex items-center bg-greenColor text-white text-xs py-1.5 px-3 gap-x-1 rounded-md sm:text-base sm:py-2 sm:px-3"
                >
                  <IoCall />
                  Call Us
                </a>
              </div>
              <div className="flex w-full justify-between items-center">
                <p className="flex flex-col text-sm font-bold sm:text-xl">
                  caledonkababs@gmail.com{" "}
                  <span className="text-xs font-light">
                    for food related query & problem
                  </span>
                </p>
                <a
                  href="mailto:caledonkababs@gmail.com"
                  className="flex items-center bg-greenColor text-white text-xs py-1.5 px-2 gap-x-1 rounded-md sm:text-base sm:py-2 sm:px-3"
                >
                  Send Mail <IoMail />
                </a>
              </div>
              <div className="flex w-full justify-between items-center">
                <p className="flex flex-col text-sm font-bold sm:text-xl">
                  +919147375833
                  <span className="text-xs font-light">
                    for technical support
                  </span>
                </p>
                <a
                  href="tel:+919147375833"
                  className="flex items-center bg-greenColor text-white text-xs py-1.5 px-3 gap-x-1 rounded-md sm:text-base sm:py-2 sm:px-3"
                >
                  <IoCall />
                  Call Us
                </a>
              </div>
              <div className="flex w-full justify-between items-center">
                <p className="flex flex-col text-sm font-bold sm:text-xl">
                  office@martiancorporation.com{" "}
                  <span className="text-xs font-light">
                    for technical support
                  </span>
                </p>
                <a
                  href="mailto:office@martiancorporation.com"
                  className="flex items-center bg-greenColor text-white text-xs py-1.5 px-2 gap-x-1 rounded-md sm:text-base sm:py-2 sm:px-3"
                >
                  Send Mail <IoMail />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
