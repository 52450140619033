import toast from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { orderEndpoints } from "../apis";

const {
  CREATE_ORDER_LOGGED_IN_API,
  CREATE_ORDER_REQUEST_API,
  CREATE_ORDER_API,
  UPDATE_ORDER_STATUS_API,
  GET_USER_ORDERS_API,
  GET_ADMIN_ORDERS_API,
  GET_ORDERS_ID_API,
  GET_ORDERS_REQUEST_API,
  ORDER_ACCEPT_API,
  ORDER_REJECT_API,
} = orderEndpoints;

// create order status
export const createOrderforLoggedIn = async (token, formData) => {
  const toastId = toast.loading("Loading...");
  let result = false;
  try {
    const response = await apiConnector(
      "POST",
      CREATE_ORDER_REQUEST_API,
      formData,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response.data.error) {
      throw new Error(response.data.error);
    }
    toast.dismiss(toastId);
    toast.success("Order Placed");
    result = true;
  } catch (error) {
    console.log("CREATE_ORDER_API API ERROR............", error);
    toast.dismiss(toastId);
    toast.error(error.message);
  }
  return result;
};

export const createOrderforNonLoggedIn = async (token, formData) => {
  const toastId = toast.loading("Loading...");
  let result = false;
  try {
    const response = await apiConnector("POST", CREATE_ORDER_API, formData);

    if (response.data.error) {
      throw new Error(response.data.error);
    }
    toast.dismiss(toastId);
    toast.success("Order Placed");
    result = true;
  } catch (error) {
    console.log("CREATE_ORDER_API API ERROR............", error);
    toast.dismiss(toastId);
    toast.error(error);
  }
  return result;
};

export const OrderAccept = async (token, data) => {
  const toastId = toast.loading("Loading...");
  let result = false;
  try {
    const response = await apiConnector("POST", ORDER_ACCEPT_API, data, {
      Authorization: `Bearer ${token}`,
    });

    if (response.data.error) {
      throw new Error(response.data.error);
    }
    toast.dismiss(toastId);
    toast.success("Order Accepted");
    result = true;
  } catch (error) {
    console.log("ORDER_ACCEPT_API API ERROR............", error);
    toast.dismiss(toastId);
    toast.error(error);
  }
  return result;
};

export const OrderReject = async (token, data) => {
  const toastId = toast.loading("Loading...");
  let result = false;
  try {
    const response = await apiConnector("POST", ORDER_REJECT_API, data, {
      Authorization: `Bearer ${token}`,
    });

    if (response.data.error) {
      throw new Error(response.data.error);
    }
    toast.dismiss(toastId);
    toast.success("Order Rejected");
    result = true;
  } catch (error) {
    console.log("ORDER_REJECT_API API ERROR............", error);
    toast.dismiss(toastId);
    toast.error(error);
  }
  return result;
};

export const getAllOrderAdmin = async (token) => {
  let result = [];
  try {
    const response = await apiConnector("GET", GET_ADMIN_ORDERS_API, null, {
      Authorization: `Bearer ${token}`,
    });
    if (response?.data?.error) {
      throw new Error(response.data.message);
    }
    result = response?.data;
  } catch (error) {
    console.log("GET_ADMIN_ORDERS_API API ERROR............", error);
    toast.error(error.message);
  }
  return result;
};

export const getAllOrderRequestAdmin = async (token) => {
  let result = [];
  try {
    const response = await apiConnector("GET", GET_ORDERS_REQUEST_API, null, {
      Authorization: `Bearer ${token}`,
    });

    console.log(response);
    if (response?.data?.error) {
      throw new Error(response.data.message);
    }
    result = response?.data;
  } catch (error) {
    console.log("GET_ORDERS_REQUEST_API API ERROR............", error);
    toast.error(error.message);
  }
  return result;
};

export const getAllOrderUser = async (token) => {
  let result = [];
  try {
    const response = await apiConnector("GET", GET_USER_ORDERS_API, null, {
      Authorization: `Bearer ${token}`,
    });
    if (response?.data?.error) {
      throw new Error(response.data.message);
    }
    result = response?.data;
  } catch (error) {
    console.log("GET_USER_ORDERS_API API ERROR............", error);
    toast.error(error.message);
  }
  return result;
};

export const getOrderById = async (data, token) => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      GET_ORDERS_ID_API + `/${data.order_id}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.data?.error) {
      throw new Error(response.data.error);
    }
    result = response?.data;
  } catch (error) {
    console.log("GET_ORDERS_ID_API API ERROR............", error);
    toast.error(error.message);
  }
  return result;
};

export const updateOrderStatus = async (token, data) => {
  const toastId = toast.loading("Loading...");
  let result = false;
  try {
    const response = await apiConnector("PUT", UPDATE_ORDER_STATUS_API, data, {
      Authorization: `Bearer ${token}`,
    });

    if (response.data.error) {
      throw new Error(response.data.message);
    }
    toast.dismiss(toastId);
    toast.success("Status Updated Successfully");
    result = true;
  } catch (error) {
    console.log("UPDATE_ORDER_STATUS_API API ERROR............", error);
    toast.dismiss(toastId);
    toast.error("Could Not Update Status");
  }
  return result;
};
