import React from "react";
import { Link } from "react-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import { IoMdArrowForward, IoMdArrowBack } from "react-icons/io";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const CategoryList = (_this) => {
  const breakpoints = {
    275: {
      slidesPerView: 2,
    },
    300: {
      slidesPerView: 2,
    },

    370: {
      slidesPerView: 3,
    },

    480: {
      slidesPerView: 3,
    },

    640: {
      slidesPerView: 3,
    },

    768: {
      slidesPerView: 4,
    },

    1024: {
      slidesPerView: 4,
    },

    1280: {
      slidesPerView: 4,
    },
    1536: {
      slidesPerView: 4,
    },
    1700: {
      slidesPerView: 4,
    },
  };
  return (
    <div className="mt-6 w-full">
      <div className="w-full">
        {_this.categoryData.length === 0 ? (
          <div> No Category </div>
        ) : (
          <div className="w-full flex flex-col gap-y-6">
            <div className="w-full md:w-11/12 flex items-center justify-start">
              <h3 className="text-base sm:text-xl md:text-2xl font-bold text-black/75">
                Explore our Categories
              </h3>
            </div>
            <div className="flex items-center">
              <button className="swiper-button-prev-custom bg-black/20 p-2 sm:p-2.5 rounded-full">
                <IoMdArrowBack />
              </button>
              <Swiper
                slidesPerView={4}
                spaceBetween={7}
                navigation={{
                  nextEl: ".swiper-button-next-custom",
                  prevEl: ".swiper-button-prev-custom",
                }}
                breakpoints={breakpoints}
                modules={[Navigation]}
                className="mySwiper"
              >
                {_this?.categoryData?.map((category) => (
                  <SwiperSlide key={category._id}>
                    <div className="flex justify-center max-w-maxContent">
                      <Link
                        // to={`/products/category/${category._id}`}
                        activeClass="active"
                        className="about font-rubik py-6 text-lg leading-5 transition-all hover:text-thirdColor"
                        to={`${category._id}`}
                        spy={true}
                        // smooth={true}
                        offset={-75}
                        duration={400}
                      >
                        <div className="flex flex-col items-center gap-y-2">
                          <img
                            className="h-20 w-20 sm:h-28 sm:w-28 md:h-32 md:w-32"
                            src={`${BASE_URL + category.category_image}`}
                            loading="lazy"
                          />
                          <p className="text-center text-xs md:text-sm font-semibold text-black/70">
                            {category.category_name}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <button className="swiper-button-next-custom bg-black/20 p-2 sm:p-2.5 rounded-full">
                <IoMdArrowForward />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryList;
