import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Body from "./Body";
import { Container } from "../../components/DashbordSideBar";
import {
  createCategory,
  updateCategory,
  getAllCategory,
} from "../../services/operations/categoryApi";
import { useSelector } from "react-redux";

const AddCategory = () => {
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [addCategoryModalData, setAddCategoryModalData] = useState({
    category_name: "",
    category_description: "",
  });
  const [editCategoryModalData, setEditCategoryModalData] = useState({
    category_id: "",
    category_name: "",
    category_description: "",
  });
  const [addCategoryImageData, setAddCategoryImageData] = useState(null);
  const [editCategoryImageData, setEditCategoryImageData] = useState(null);

  const addCategoryModalToggle = () => {
    setAddCategoryModal(!addCategoryModal);
  };

  const openEditCategoryModal = (
    category_id,
    category_name,
    category_description
  ) => {
    editCategoryModalData.category_id = category_id;
    editCategoryModalData.category_name = category_name;
    editCategoryModalData.category_description = category_description;
    setEditCategoryModal(true);
  };

  const editCategoryModalToggle = () => {
    setEditCategoryModal(!editCategoryModal);
  };

  const AddCategorySubmit = async () => {
    if (
      addCategoryModalData.category_name === "" ||
      addCategoryModalData.category_description === "" ||
      addCategoryImageData === null
    )
      return toast.error("All Fields are required");
    const formData = new FormData();
    formData.append("category_name", addCategoryModalData.category_name);
    formData.append(
      "category_description",
      addCategoryModalData.category_description
    );
    formData.append("category_image_file", addCategoryImageData.data);

    const result = await createCategory(formData, token);
    getCategories();
    setAddCategoryModalData((prev) => ({
      ...prev,
      category_name: "",
      category_description: "",
    }));
    setAddCategoryImageData(null);
    setAddCategoryModal(false);
  };

  const EditCategorySubmit = async () => {
    if (
      editCategoryModalData.category_name === "" ||
      editCategoryModalData.category_description === ""
    )
      return toast.error("All Fields are required");
    const formData = new FormData();
    formData.append("category_id", editCategoryModalData.category_id);
    formData.append("category_name", editCategoryModalData.category_name);
    formData.append(
      "category_description",
      editCategoryModalData.category_description
    );
    if (editCategoryImageData !== null) {
      formData.append("category_image_file", editCategoryImageData?.data);
    }

    const result = await updateCategory(formData, token);
    getCategories();
    setEditCategoryModal(false);
    setEditCategoryImageData(null);
  };

  // const DeleteCategory = async();

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setLoading(true);
    try {
      const res = await getAllCategory();
      setCategoryData(res);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const _this = {
    loading,
    setLoading,
    addCategoryModal,
    setAddCategoryModal,
    AddCategorySubmit,
    addCategoryModalToggle,
    categoryData,
    setCategoryData,
    addCategoryModalData,
    setAddCategoryModalData,
    addCategoryImageData,
    setAddCategoryImageData,
    editCategoryModal,
    setEditCategoryModal,
    editCategoryModalToggle,
    editCategoryModalData,
    setEditCategoryModalData,
    editCategoryImageData,
    setEditCategoryImageData,
    EditCategorySubmit,
    openEditCategoryModal,
  };

  return (
    <Container>
      <Body {..._this} />
    </Container>
  );
};
export default AddCategory;
