import React from "react";
import Orders from "./Orders";
import OrderRequestModal from "./OrderRequestModal";

const Body = (_this) => {
  return (
    <>
      <Orders {..._this} />
      <OrderRequestModal {..._this} />
    </>
  );
};

export default Body;
