import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import toast from "react-hot-toast";
import Body from "./Body";
import { Container } from "../../components/DashbordSideBar";
import {
  OrderAccept,
  OrderReject,
  getAllOrderAdmin,
  getAllOrderRequestAdmin,
} from "../../services/operations/ordersApi";
import { useSelector } from "react-redux";
import audioSound from "../../assets/Audio/notification_sound.mp3";
import API from "../../services/api";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const audio = new Audio(audioSound);
audio.load();
const Orders = () => {
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [allOrdersData, setAllOrdersData] = useState({});
  const [userActiveOrders, setUserActiveOrders] = useState([]);
  const [userCancelledOrders, setUserCancelledOrders] = useState([]);
  const [userCompletedOrders, setUserCompletedOrders] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [requestOrderData, setRequestOrderData] = useState([]);
  const [requestOrderModal, setRequestOrderModal] = useState(false);

  const playorOffAudio = (on) => {
    if (on) {
      audio.play();
    } else {
      audio.pause();
    }
  };

  const tabChangeHandler = (key) => {
    if (key === "1") setOrdersData(userActiveOrders);
    else if (key === "2") setOrdersData(userCompletedOrders);
    else setOrdersData(userCancelledOrders);
  };

  const ChangeOrderStatus = async (data) => {
    setLoading(true);
    const loadingId = toast.loading("Loading...");
    API.order
      .ChangeOrderStatus(token, data)
      .then((response) => {
        if (response) {
          changeOrderStatus();
          getOrders();
          toast.success("Status Changed");
        }
      })
      .finally(() => {
        setLoading(false);
        toast.dismiss(loadingId);
      });
  };

  const getOrders = async () => {
    setLoading(true);
    try {
      const res = await getAllOrderAdmin(token);
      setAllOrdersData(res);
      setUserActiveOrders(res.userActiveOrders);
      setUserCancelledOrders(res.userCancelledOrders);
      setUserCompletedOrders(res.userCompletedOrders);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getOrderRequest = async () => {
    setLoading(true);
    try {
      const res = await getAllOrderRequestAdmin(token);
      setRequestOrderData(res);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const orderAccept = async (orderRequest_id) => {
    setLoading(true);
    try {
      const res = await OrderAccept(token, { orderRequest_id });
      playorOffAudio(false);
      getOrderRequest();
      getOrders();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const orderReject = async (orderRequest_id) => {
    setLoading(true);
    try {
      const res = await OrderReject(token, { orderRequest_id });
      playorOffAudio(false);
      getOrderRequest();
      getOrders();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getOrders();
    getOrderRequest();
  }, []);

  useEffect(() => {
    setOrdersData(userActiveOrders);
  }, [userActiveOrders]);

  // Socket
  const changeOrderStatus = (e) => {
    // const socket = io.connect(BASE_URL);
    // socket.emit("send_change_order_status", e);
  };

  useEffect(() => {
    const socket = io.connect(BASE_URL);
    socket.on("receiving_order_cancel_request", (e) => {
      playorOffAudio(true);
      toast.success("New Cancel Request");
      getOrders();
    });

    socket.on("receiving_order_placed", (e) => {
      playorOffAudio(true);
      toast.success("New Order");
      getOrders();
      getOrderRequest();
    });
  }, []);

  const _this = {
    loading,
    setLoading,
    allOrdersData,
    setAllOrdersData,
    requestOrderModal,
    setRequestOrderModal,
    ordersData,
    setOrdersData,
    tabChangeHandler,
    ChangeOrderStatus,
    requestOrderData,
    setRequestOrderData,
    orderAccept,
    orderReject,
  };
  return (
    <Container>
      <Body {..._this} />
    </Container>
  );
};
export default Orders;
