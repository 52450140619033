import React from "react";
import { Modal, ConfigProvider } from "antd";

const DeleteAddressModal = (_this) => {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            borderRadiusLG: 0,
          },
        }}
      >
        <Modal
          open={_this.deleteAddressModal}
          onCancel={() => _this.setDeleteAddressModal(false)}
          centered={true}
          footer={
            <div className="flex justify-end gap-x-3">
              <button
                onClick={() => _this.setDeleteAddressModal(false)}
                className="border-[2px] p-1.5 border-primaryColor text-primaryColor font-bold"
              >
                Cancel
              </button>
              <button
                onClick={() => _this.deleteAddressSubmit()}
                className="border-[2px] p-1.5 border-primaryColor bg-primaryColor text-white font-bold"
              >
                Delete
              </button>
            </div>
          }
        >
          <div className="flex flex-col justify-center items-start sm:px-3 sm:py-3 gap-y-2 min-h-[60px]">
            <p className="font-bold text-sm text-denger">
              Delete this Address ?
            </p>
            <p className="text-xs font-extralight">
              You can no longer deliver to {_this.deleteAddressData.street}
            </p>
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default DeleteAddressModal;
