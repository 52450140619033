import React from "react";
import { Link } from "react-router-dom";
import { Empty, Skeleton } from "antd";
import moment from "moment";
import { FaArrowRight } from "react-icons/fa";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Body = (_this) => {
  return (
    <>
      <div className="w-full flex justify-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)] bg-black/5">
        <div className="flex flex-col w-full h-full sm:w-10/12 md:w-8/12 lg:w-6/12 max-w-maxContent gap-y-4 py-3 ">
          <div className="w-full h-full flex flex-col gap-y-3">
            <span className="text-xs text-black/70 pl-3 uppercase">Orders</span>
            <div className="h-[1px] bg-black/10 my-1"></div>
            {_this.loading ? (
              <div className="flex flex-col gap-y-3">
                <Skeleton />
                <Skeleton />
              </div>
            ) : (
              <div>
                {_this.ordersData.length === 0 ? (
                  <div className="flex  justify-center h-full">
                    <Empty description="No Orders" />
                  </div>
                ) : (
                  <div className="flex flex-col gap-y-4 h-full">
                    {_this.ordersData.map((order, index) => {
                      return (
                        <Link
                          to={`/customer/accounts/order/${order._id}`}
                          key={order._id}
                        >
                          <div className="bg-white py-3 px-4">
                            <div className="flex flex-col gap-y-6">
                              <div className="flex justify-between items-center">
                                <div>
                                  <h5 className="text-md font-bold text-black/70">
                                    {order.order_status}
                                  </h5>
                                  <p className="text-xs font-light text-black/75">
                                    {moment(order.order_date).format(
                                      "Do MMMM YYYY, h:mm a"
                                    )}
                                  </p>
                                </div>
                                <p
                                  className={`text-sm  ${
                                    order.order_status === "Cancelled"
                                      ? "text-black/60"
                                      : "text-greenColor"
                                  } font-semibold
                                flex items-center gap-2`}
                                >
                                  {" "}
                                  View Order
                                  <FaArrowRight />
                                </p>
                              </div>
                              <div className="flex flex-row items-center gap-x-3">
                                {order.order_items.slice(0, 3).map((items) => {
                                  return (
                                    <div key={items._id}>
                                      <img
                                        alt="product_image"
                                        src={`${
                                          BASE_URL + items.product.product_image
                                        }`}
                                        className={`h-12 xs:h-14 max-h-16 object-cover rounded-md ${
                                          order.order_status === "Cancelled" &&
                                          "grayscale"
                                        }`}
                                      />
                                    </div>
                                  );
                                })}
                                {order.order_items.length > 3 && (
                                  <p className="text-xs font-light text-black/65">
                                    more
                                  </p>
                                )}
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
