import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import Navbar from "../../components/Navbar";
import Body from "./Body";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getOrderById,
  updateOrderStatus,
} from "../../services/operations/ordersApi";
import toast from "react-hot-toast";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const socket = io.connect(BASE_URL);

const CustomerOrderById = () => {
  const { order_id } = useParams();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");

  useEffect(() => {
    getOrderDetails();
  }, []);

  const getOrderDetails = async () => {
    setLoading(true);
    try {
      if (token) {
        const res = await getOrderById({ order_id }, token);
        setOrderData(res);
        setOrderStatus(res.order_status);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const orderCancelRequest = async (e) => {
    if (orderStatus !== "Ordered") {
      return toast.error("Cancel only available when status in Ordered status");
    }

    const data = {
      order_status: "Cancelled",
      order_id: e._id,
    };
    const res = await updateOrderStatus(token, data);
    socket.emit("send_order_cancel_request", e);
    getOrderDetails();
  };

  socket.on("receiving_change_order_status", (e) => {
    if (orderData?._id === e.order_id) {
      setOrderStatus(e.order_status);
    }
  });

  const _this = {
    loading,
    setLoading,
    orderData,
    setOrderData,
    orderCancelRequest,
    orderStatus,
    setOrderStatus,
  };
  return (
    <>
      <Navbar />
      <div className="mt-[65px] mb-[60px] md:mb-[0px] md:mt-[90px]">
        <Body {..._this} />
      </div>
    </>
  );
};

export default CustomerOrderById;
