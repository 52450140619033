import React from "react";
import successImage from "../../assets/images/success.png";
import { Link } from "react-router-dom";
const Body = () => {
  return (
    <>
      <div className="w-full flex justify-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)]">
        <div className="flex flex-col justify-center items-center w-full sm:w-11/12 max-w-maxContent gap-y-8 py-8">
          <div className="bg-white rounded-full">
            <img src={successImage} className="h-36 sm:h-50" />
          </div>
          <div className="text-center">
            <h5 className="text-lg md:text-xl text-black/80">
              Order has been placed
            </h5>
            <p className="text-base font-light text-black/80">
              waiting for admin accept
            </p>
          </div>
          <Link
            to="/"
            className="bg-greenColor text-white px-2 py-2 font-semibold rounded-sm"
          >
            Continue Shopping
          </Link>
        </div>
      </div>
    </>
  );
};

export default Body;
