import toast from "react-hot-toast";
import { productEndpoints } from "../apis";
import { apiConnector } from "../apiConnector";

const {
  GET_ALL_PRODUCTS_API,
  CREATE_PRODUCT_API,
  UPDATE_PRODUCT_API,
  GET_PRODUCTS_BY_CATEGORY_API,
  UPDATE_PRODUCT_AVAILABILITY_API,
  UPDATE_UNIVERSAL_AVAILABILITY_API,
  UPDATE_PRODUCT_QUANTITY_API,
  DELETE_PRODUCT_API,
  GET_ALL_PRODUCTS_BY_CATEGORY_API,
} = productEndpoints;

export const getAllProducts = async () => {
  let result = [];
  try {
    const response = await apiConnector("GET", GET_ALL_PRODUCTS_API);
    if (!response?.data) {
      throw new Error("Could not fetched Product");
    }
    result = response?.data;
  } catch (error) {
    console.log("GET_ALL_PRODUCTS_API API ERROR............", error);
    toast.error(error.message);
  }
  return result;
};

export const getAllProductsByCategory = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      GET_ALL_PRODUCTS_BY_CATEGORY_API
    );
    if (!response?.data) {
      throw new Error("Could not fetched Product");
    }
    result = response?.data;
  } catch (error) {
    console.log(
      "GET_ALL_PRODUCTS_BY_CATEGORY_API API ERROR............",
      error
    );
    toast.error(error.message);
  }
  return result;
};

export const getProductsByCategory = async (category_id) => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      GET_PRODUCTS_BY_CATEGORY_API + `/${category_id}`
    );

    if (response?.data.error) {
      throw new Error("Could not fetched Product");
    }
    result = response?.data;
  } catch (error) {
    console.log("GET_PRODUCTS_BY_CATEGORY_API API ERROR............", error);
    toast.error(error.message);
  }
  return result;
};

// add the Product details
export const createProduct = async (data, token) => {
  let result = null;
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector("POST", CREATE_PRODUCT_API, data, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
    if (response?.data?.error) {
      throw new Error(response?.data?.error);
    }
    toast.success("Product Added Successfully");
    result = response?.data;
  } catch (error) {
    console.log("CREATE PRODUCT API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};

export const updateProduct = async (data, token) => {
  let result = null;
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector("PUT", UPDATE_PRODUCT_API, data, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
    if (response?.data.error) {
      throw new Error("Could Not Update Product Details");
    }
    toast.success("Product Updated");
    result = response?.data;
  } catch (error) {
    console.log("UPDATE PRODUCT API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};

// delete a Product
export const deleteProduct = async (data, token) => {
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector("DELETE", DELETE_PRODUCT_API, data, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    if (response?.data?.error) {
      throw new Error("Could Not Delete Product");
    }
    toast.success("Product Deleted");
  } catch (error) {
    console.log("DELETE PRODUCT API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
};

export const changeAvailable = async (data, token) => {
  let result = null;
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector(
      "PUT",
      UPDATE_PRODUCT_AVAILABILITY_API + `/${data.product_id}`,
      null,
      {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.data.error) {
      throw new Error("Could Not Update Product Details");
    }
    toast.success("Product Updated");
    result = response?.data;
  } catch (error) {
    console.log("UPDATE PRODUCT API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};

export const changeUniversalAvailable = async (data, token) => {
  let result = null;
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector(
      "PUT",
      UPDATE_UNIVERSAL_AVAILABILITY_API,
      data,
      {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.data.error) {
      throw new Error("Could Not Update Product Details");
    }
    toast.success("Product Updated");
    result = response?.data;
  } catch (error) {
    console.log("UPDATE PRODUCT API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};

export const updateProductQuntity = async (data, product_id, token) => {
  let result = null;
  const toastId = toast.loading("Loading...");
  try {
    if (data.product_quantity_logic < 0 && data.product_quantity === 0) {
      toast.dismiss(toastId);
      return toast.error("Product stock in min limit");
    }

    const response = await apiConnector(
      "PUT",
      UPDATE_PRODUCT_QUANTITY_API + `/${product_id}`,
      data,
      {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.data.error) {
      throw new Error("Could Not Update Product");
    }
    toast.success("Product Updated");
    result = response?.data;
  } catch (error) {
    console.log("UPDATE_PRODUCT_QUANTITY_API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};
