import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Body from "./Body";
import toast from "react-hot-toast";
import {
  updateEmail,
  updatePhoneNumber,
} from "../../services/operations/authApi";

const CustomerEditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const [phoneNumberVerifyModal, setPhoneNumberVerifyModal] = useState(false);
  const [emailVerifyModal, setEmailVerifyModal] = useState(false);
  const [editPhoneNumberData, setEditPhoneNumberData] = useState({
    phone_number: user.phone_number,
    otp: "",
  });
  const [editEmailData, setEditEmailData] = useState({
    email: user.email,
    otp: "",
  });
  const [phoneOtp, setPhoneOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");

  const updatePhoneNumberSubmit = async () => {
    if (user.phone_number === editPhoneNumberData.phone_number)
      return toast.error("Change Phone number to update");

    if (phoneOtp === "") {
      const res = dispatch(
        updatePhoneNumber(editPhoneNumberData, token, navigate)
      );
      console.log(res);
      if (res) {
        setPhoneNumberVerifyModal(true);
      }
    } else {
      const data = {
        phone_number: editPhoneNumberData.phone_number,
        otp: phoneOtp,
      };
      const res = dispatch(updatePhoneNumber(data, token, navigate));
      setPhoneNumberVerifyModal(false);
    }
  };

  const updateEmailSubmit = async () => {
    if (user.email === editEmailData.email)
      return toast.error("Change Email to update");

    if (emailOtp === "") {
      const res = dispatch(updateEmail(editEmailData, token, navigate));
      setEmailVerifyModal(true);
    } else {
      const data = {
        email: editEmailData.email,
        otp: emailOtp,
      };
      console.log(data);
      const res = dispatch(updateEmail(data, token, navigate));
      setEmailVerifyModal(false);
    }
  };
  const _this = {
    editPhoneNumberData,
    setEditPhoneNumberData,
    editEmailData,
    setEditEmailData,
    updatePhoneNumberSubmit,
    phoneNumberVerifyModal,
    setPhoneNumberVerifyModal,
    emailVerifyModal,
    setEmailVerifyModal,
    updateEmailSubmit,
    phoneOtp,
    setPhoneOtp,
    emailOtp,
    setEmailOtp,
  };
  return (
    <>
      <Navbar />
      <div className="mt-[65px] mb-[60px] md:mb-[0px] md:mt-[90px]">
        <Body {..._this} />
      </div>
    </>
  );
};

export default CustomerEditProfile;
