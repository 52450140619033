import React from "react";
import Body from "./Body";
import { Container } from "../../components/DashbordSideBar";

const ManageTeams = () => {
  return (
    <Container>
      <Body />
    </Container>
  );
};
export default ManageTeams;
