import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Body from "./Body";
import { getProductsByCategory } from "../../services/operations/productApi";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../reducer/slices/cartSlice";

const CategoryProducts = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [productData, setProductData] = useState([]);
  const [productDetailsModal, setProductDetailsModal] = useState(false);

  const { category_id } = useParams();

  useEffect(() => {
    getCategoriesProduct();
  }, []);

  const getCategoriesProduct = async () => {
    setLoading(true);
    try {
      const res = await getProductsByCategory(category_id);
      setCategoryData(res?.categoryDetails);
      setProductData(res?.products);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const addToCartHandler = (product) => {
    dispatch(addToCart(product));
  };

  const _this = {
    loading,
    setLoading,
    productData,
    setProductData,
    categoryData,
    setCategoryData,
    addToCartHandler,
    productDetailsModal,
    setProductDetailsModal,
  };
  return (
    <>
      <Navbar />
      <div className="mt-[65px] mb-[60px] md:mb-[0px] md:mt-[90px]">
        <Body {..._this} />
      </div>
    </>
  );
};

export default CategoryProducts;
